/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import EmojiPicker, {Theme} from 'emoji-picker-react'
import {useThemeMode} from '../../../partials'

type Props = {
  width?: string
  onEmojiClick: any
}

const EmojiWidget: React.FC<Props> = ({width = '', onEmojiClick}) => {
  let {mode} = useThemeMode()
  let theme_mode = mode === 'dark' ? Theme.DARK : Theme.LIGHT
  return (
    <>
      <EmojiPicker
        previewConfig={{showPreview: false}}
        theme={theme_mode}
        onEmojiClick={onEmojiClick}
        height={300}
        width={width ? width : 630}
      />
    </>
  )
}

export {EmojiWidget}
