export const isSameSenderMargin = (messages, m, i, userId) => {
  if (
    i < messages.length - 1 &&
    messages[i + 1].sender._id === m.sender._id &&
    messages[i].sender._id !== userId
  )
    return 33
  else if (
    (i < messages.length - 1 &&
      messages[i + 1].sender._id !== m.sender._id &&
      messages[i].sender._id !== userId) ||
    (i === messages.length - 1 && messages[i].sender._id !== userId)
  )
    return 0
  else return 'auto'
}

export const isSameSender = (messages, m, i, userId) => {
  return (
    i < messages.length - 1 &&
    (messages[i + 1].sender !== m.sender || messages[i + 1].sender === undefined) &&
    messages[i].sender !== userId
  )
}

export const isLastMessage = (messages, i, userId) => {
  return (
    i === messages.length - 1 &&
    messages[messages.length - 1].sender._id !== userId &&
    messages[messages.length - 1].sender._id
  )
}

export const isSameUser = (messages, m, i) => {
  return i > 0 && messages[i - 1].sender._id === m.sender._id
}

export const getSender = (users) => {
  return users[0]?.id === Number(localStorage.getItem('user_id')) ? users[0]?.name : users[0]?.name
}

export const getSenderFull = (users) => {
  return users[0]?.id === Number(localStorage.getItem('user_id')) ? users[1] : users[0]
}

export const getSenderProfileName = (users) => {
  const name =
    users[0]?.id === Number(localStorage.getItem('user_id')) ? users[1]?.name : users[0]?.name
  if (name?.includes(' ')) {
    const splitName = name?.split(' ')
    return ` ${splitName ? splitName[0]?.charAt(0).toUpperCase() : ''}${
      splitName && splitName[1] ? splitName[1]?.charAt(0).toUpperCase() : ''
    }`
  } else {
    return ` ${name?.charAt(0).toUpperCase()}`
  }
}

export const getProfileName = (name) => {
  if (name.includes(' ')) {
    const splitName = name?.split(' ')
    return ` ${splitName ? splitName[0]?.charAt(0).toUpperCase() : ''}${
      splitName && splitName[1] ? splitName[1]?.charAt(0).toUpperCase() : ''
    }`
  } else {
    return ` ${name?.charAt(0).toUpperCase()}`
  }
}
