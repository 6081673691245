export const {
  REACT_APP_API_URL: API_BASE,
  REACT_APP_BANANRY_URL: BANANRY_URL,
  REACT_APP_BANANRY_API_URL: BANANRY_API_URL,
  REACT_APP_EVENT_URL: EVENT_BACKEND_URL,
} = process.env

export const API_USERS_LIST = `${API_BASE}user/user_list`
export const API_CHAT_LIST = `${API_BASE}chat/chat_list`
export const API_ACCESS_CHAT_LIST = `${API_BASE}chat/access_chat`
export const API_ACCESS_MESSAGES_LIST = `${API_BASE}message/all_messages`
export const API_USER_SUGGESTION_LIST = `${API_BASE}user/user_suggestions_with_search_and_pagination`
export const API_FRIEND_USER_SUGGESTION_LIST = `${API_BASE}user/friend_user_suggestions`

export const API_GET_COUNTRY_LIST = `${API_BASE}user/get_countries`
export const API_SET_THEME_DETAILS = `${API_BASE}user/set_theme_details`
export const CLEAR_CHAT = `${API_BASE}chat/clear_chat`
export const API_UPLOAD_CHAT_IMAGE = `${API_BASE}chat/upload_chat_image`

export const API_FEED_LIST = `${API_BASE}post/feed`
export const API_CREATE_POST = `${API_BASE}post/create_post`
export const API_MY_POST_LIST = `${API_BASE}post/my_post_list`
export const API_LIKE_POST = `${API_BASE}post/like_post`
export const API_REMOVE_LIKE_POST = `${API_BASE}post/remove_like_post`
export const API_GET_POST_DETAILS = `${API_BASE}post/my_post_details`
export const API_POST_LIKE_USERS = `${API_BASE}post/post_like_users`

export const API_USER_DETAILS = `${API_BASE}user/user_Details`
export const API_USER_FOLLOWERS = `${API_BASE}user/user_followers`
export const API_USER_FOLLOWINGS = `${API_BASE}user/user_following`
export const API_USER_FOLLOW = `${API_BASE}user/follow_user`
export const API_USER_UNFOLLOW = `${API_BASE}user/unfollow_user`
export const API_USER_REMOVE = `${API_BASE}user/remove_user`
export const API_GET_USERNAME_LIST = `${API_BASE}user/get_username_list`
export const API_USER_SIGNOUT = `${EVENT_BACKEND_URL}sign-out.php`

export const API_COMMENT_LIST = `${API_BASE}post/comment_list`
export const ADD_COMMENT = `${API_BASE}post/add_comment`

export const API_PHOTO_LIST = `${API_BASE}photo/photo_list_using_folder_id`
export const API_SHARED_PHOTO_LIST = `${API_BASE}photo/shared_photo_list`
export const API_PHOTO_CREATE = `${API_BASE}photo/upload_photo`
export const API_PHOTO_DELETE = `${API_BASE}photo/delete_photo`
export const API_SHARED_PHOTO_FRIEND = `${API_BASE}photo/share_photo`
export const API_SHARED_PHOTO_DELETE = `${API_BASE}photo/remove_shared_photo`
export const API_FOLDER_LIST = `${API_BASE}photo/folder_list`
export const API_FOLDER_DELETE = `${API_BASE}photo/delete_folder`
export const API_SHARED_FOLDER_LIST = `${API_BASE}photo/shared_folder_list`
export const API_SHARED_FOLDER = `${API_BASE}photo/share_folder`
export const API_REMOVE_SHARED_FOLDER = `${API_BASE}photo/remove_shared_folder`

export const API_ADVERTISEMENT_LIST = `${API_BASE}ads/get_advertisement`
export const API_VIEW_AD = `${API_BASE}ads/view_ad`
export const API_CLICK_AD = `${API_BASE}ads/click_ad`

export const API_SEARCH = `${BANANRY_API_URL}songs/search`
export const API_FRIEND_LIST = `${API_BASE}friend/getMyFriends`