import * as ACTION_TYPES from './Types'
import {initialStateType} from '../../types'

const initialState = {
  message: '',
  error: null,
  userList: {total_page: 0, total_record: [], record: []},
  suggestUsers: {total: 0, users: []},
  suggestFriendUsers: [],
  user: {},
  userFollowers: {total_page: 0, total_record: [], record: []},
  userFollowings: {total_page: 0, total_record: [], record: []},
  page: 1,
  length: 9,
  countries: [],
  usernameList: {total_page: 0, total_record: [], record: []},
}

const userReducer = (
  state: initialStateType = initialState,
  action: {type: any; payload: {data: any; message: any}}
) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: {
          // total_page: action?.payload?.data?.total_page,
          total_record: action?.payload?.data?.total_record,
          record: action?.payload?.data?.users,
        },
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_USER_SUGG_LIST_SUCCESS:
      return {
        ...state,
        suggestUsers: {
          total: action.payload.data.total,
          users: action.payload.data.users,
        },
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_FRIEND_USER_SUGG_LIST_SUCCESS:
      return {
        ...state,
        suggestFriendUsers: action.payload.data,
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countries: action.payload.data,
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.UPDATE_FRIEND_USER_SUGG_LIST:
      let updated_records = state?.suggestFriendUsers?.users?.map((rec: any) => {
        if (rec.id == action.payload.data.user_id) {
          rec.status = action.payload.data.status
        }
        return rec
      })

      return {
        ...state,
        suggestFriendUsers: {users: updated_records, user_ids: state?.suggestFriendUsers?.user_ids},
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_USER_FOLLOWERS_SUCCESS:
      return {
        ...state,
        userFollowers: {
          total_page: action?.payload?.data?.total_page,
          total_record: action?.payload?.data?.total_record,
          record: action?.payload?.data?.users,
        },
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_USER_FOLLOWINGS_SUCCESS:
      return {
        ...state,
        userFollowings: {
          total_page: action?.payload?.data.total_page,
          total_record: action?.payload?.data.total_record,
          record: action?.payload?.data.users,
        },
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_USERNAME_LIST_SUCCESS:
      return {
        ...state,
        usernameList: {
          total_page: action?.payload?.data.total_page,
          total_record: action?.payload?.data.total_record,
          record: action?.payload?.data.users,
        },
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_USER_LIST_ERROR:
    case ACTION_TYPES.FETCH_USER_SUGG_LIST_ERROR:
    case ACTION_TYPES.FETCH_USERNAME_LIST_ERROR:
    case ACTION_TYPES.FETCH_USER_FOLLOW_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.FETCH_USER_FOLLOW_ERROR:

    case ACTION_TYPES.FETCH_USER_UNFOLLOW_SUCCESS:
    case ACTION_TYPES.FETCH_USER_UNFOLLOW_ERROR:
    case ACTION_TYPES.FETCH_COUNTRY_LIST_ERROR:
      break
    default:
      return state
  }
}

export default userReducer
