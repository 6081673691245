import * as ACTION_TYPES from './Types'
import {initialMessageStateType} from '../../types'

const initialState = {
  message: '',
  error: null,
  messageList: {total: 0, filteredTotal: 0},
  chat: {},
  page: 1,
  length: 10,
}

const messageReducer = (
  state: initialMessageStateType = initialState,
  action: {type: any; payload: {status: number; data: {
    length: any;accessToken: any
}; message: any}}
) => {
  switch (action.type) {
    case ACTION_TYPES.CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        messageList: {total: action.payload.data.length, filteredTotal: action.payload.data},
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.CHAT_MESSAGES_ERROR:
    case ACTION_TYPES.SEND_MESSAGES_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload.data,
      }

    default:
      return state
  }
}

export default messageReducer
