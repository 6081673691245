import axios from 'axios'
import * as ACTION_TYPES from './Types'
import {API_ADVERTISEMENT_LIST, API_VIEW_AD, API_CLICK_AD} from '../../constants/Api'

// GET ads LIST
export const get_advertisement = () => (dispatch: any) => {
  return axios
    .get(API_ADVERTISEMENT_LIST)
    .then((res) => {
      dispatch({type: ACTION_TYPES.FETCH_ADVT_SUCCESS, payload: res.data})
      return res.data
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.FETCH_ADVT_ERROR,
        payload: err.response,
      })
      throw err
    })
}

// view ad
export const view_ad = (data: any) => (dispatch: any) => {
  return axios
    .post(API_VIEW_AD, data)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw err
    })
}

// click ad
export const click_ad = (data: any) => (dispatch: any) => {
  return axios
    .post(API_CLICK_AD, data)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw err
    })
}
