import { FC, useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../helpers'

type Props = {
  group_name?: any
  records: any[]
  setSearch?: any
}

let SearchInner: FC<Props> = (props) => {
  return (
    <>
      <div className="group_header_color mb-3">
        <span>{props?.group_name}</span>
      </div>
      {props.records.map((record: any, index: number) => (
        <div key={index} onClick={() => props?.setSearch("")}>
          <a href={record?.link} className='d-flex align-items-center bg bg-hover-darkgray mb-2 p-1'>
            <div className='symbol symbol-40px border-radius-none border-1 me-2'>
              <img src={toAbsoluteUrl(record?.image)} alt='' className='border-radius-none' />
            </div>
            <div className='d-flex justify-content-center flex-column me-2'>
              <h3
                className='fs-6 fw-bold me-1 lh-1 over_flow mb-1' style={{ width: "215px" }}
              >
                {record?.title || record?.name}
              </h3>
              <span className='text-gray-600 over_flow w-200px'> {record?.artist}</span>
            </div>
          </a>
        </div>
      ))}
    </>
  )
}
export { SearchInner }
