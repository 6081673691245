import { FC, useContext, useEffect, useRef, useState } from 'react'
import * as USERACTIONS from '../../../../store/User/Actions'
import { ChatContext } from '../../../../app/modules/apps/utils/ChatProvider'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { getProfileName } from '../../../../config/ChatLogics'

type Props = {
  user_list(arg0: { page: number; limit: number; user_id: string | null; search?: string }): any
  setchatModalOpen?: any
  props?: any
  chatModalOpen?: any
}

let InviteUsers: FC<Props> = (props) => {
  const intl = useIntl()
  const { addMember } = useContext(ChatContext)
  const [users, setUsers] = useState<any[]>([])
  const [search, setSearch] = useState<any>('')
  const [total, setTotal] = useState(1)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [userListMore, setUserListMore] = useState(true)
  const user_id = localStorage.getItem('user_id')
  const containerRef = useRef(null) // Ref for the scroll container

  const fetchData = () => {
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    const abortcontroller = new AbortController()
    currentPage && getUserList(currentPage, search)
    return () => {
      abortcontroller.abort()
    }
  }, [currentPage, props.chatModalOpen])

  useEffect(() => {
    if (props.chatModalOpen) {
      setCurrentPage(1)
      setUserListMore(true)
      setUsers([])
      setSearch("")
    }
  }, [props.chatModalOpen])

  const getUserList = async (page: any, search: any) => {
    await props
      .user_list({ page: page, limit: limit, user_id: localStorage.getItem('user_id'), search: search })
      .then((res: { data: any }) => {
        res.data.users?.length < limit && setUserListMore(false)
        setUsers((prev) => [...prev, ...res.data?.users])
        setTotal(res.data?.total_record)
      })
  }

  const getUserListSearch = async (page: any, search: any) => {
    await props
      .user_list({ page: page, limit: limit, user_id: localStorage.getItem('user_id'), search: search })
      .then((res: { data: any }) => {
        res.data.users?.length < limit && setUserListMore(false)
        setUsers(res.data?.users)
        setTotal(res.data?.total_record)
      })
  }

  useEffect(() => {
    getUserListSearch(1, search)
  }, [search])

  const accessChat = async (userId: number) => {
    addMember(userId)
    props.setchatModalOpen(false)
  }

  return (
    <>
      <Modal
        centered
        show={props.chatModalOpen}
        className='invite-member'
        onHide={() => props.setchatModalOpen(false)}
      >
        <ModalHeader>
          <div className='text-center mb-8  w-100'>
            <div className='d-flex justify-content-between'>
              <h1 className='mb-3 m-auto'>{intl.formatMessage({ id: 'TEXT.ADD_MEMBERS' })}</h1>
              <h3 style={{ cursor: 'pointer' }} onClick={() => props.setchatModalOpen(false)}>
                x
              </h3>
            </div>

            <div className='text-muted fw-bold fs-5'>
              {intl.formatMessage({ id: 'TEXT.ADD_MEMBER_HEADING' })}
            </div>
          </div>
        </ModalHeader>
        <ModalBody className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
          <div className='mb-10 mt-10'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6 me-5'
              />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control ps-16'
                name='search'
                placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SEARCH_EMAIL' })}
                autoComplete='off'
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                value={search}
              />
            </div>

            <div className='mh-450px scroll-y me-n7 pe-4' id='scrollableDivInvite'>
              <InfiniteScroll
                dataLength={users?.length} //This is important field to render the next data
                next={fetchData}
                hasMore={userListMore}
                loader={
                  <>
                    <br />
                    <h4 className='text-center'>{intl.formatMessage({ id: 'TEXT.LOADING' })}</h4>
                  </>
                }
                scrollableTarget='scrollableDivInvite'
                endMessage={
                  <>
                    <br />
                    <p style={{ textAlign: 'center' }}>
                      <b>
                        {users?.length > 0
                          ? intl.formatMessage({ id: 'TEXT.SEEN_RECORDS' })
                          : intl.formatMessage({ id: 'TEXT.NO_RECORDS_FOUND' })}
                      </b>
                    </p>
                  </>
                }
              >
                {users && users?.map((user, i) => {
                  return (
                    <div
                      className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'
                      key={i}
                    >
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-35px symbol-circle'>
                          {user?.profile_pic ? (
                            <a
                              href={`/profile/post/${user?.id}`}
                              className='symbol symbol-35px symbol-circle'
                            >
                              <img src={user?.profile_pic} />
                            </a>
                          ) : (
                            <a
                              href={`/profile/post/${user?.id}`}
                              className='symbol symbol-35px symbol-circle'
                            >
                              <span className={`symbol-label bg-light-danger text-danger fw-bold`}>
                                {user?.name
                                  ? `${getProfileName(user?.name)}`
                                  : user?.email?.charAt(0)?.toUpperCase()}
                              </span>
                            </a>
                          )}
                        </div>

                        <div className='ms-5'>
                          <a
                            href={`/profile/post/${user?.id}`}
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                          >
                            {user.name}
                          </a>
                          {/* <div className='fw-bold text-muted'>{user.email}</div> */}
                        </div>
                      </div>

                      <div className='ms-2 w-100px text-end'>
                        <button
                          className='btn btn-sm btn-light-primary fw-bolder theme-same-button'
                          onClick={() => accessChat(user.id)}
                        >
                          {intl.formatMessage({ id: 'BTN.ADD' })}
                        </button>
                      </div>
                    </div>
                  )
                })}
              </InfiniteScroll>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

const mapStateToProps = ({ }) => ({
  // user,
})

const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  user_list: (data: any) => dispatch(USERACTIONS.user_list(data)),
})

InviteUsers = connect(mapStateToProps, mapDispatchToProps)(InviteUsers)

export { InviteUsers }
{
  /* <div className='modal fade' id='kt_modal_invite_friends' aria-hidden='true'>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <div className='text-center mb-13'>
                {/* <h1 className='mb-3'>Invite a Friend</h1> */
}
//   <h1 className='mb-3'>Add Members</h1>

//   <div className='text-muted fw-bold fs-5'>
//     Add new members to this group chat.
//   </div>
// </div>

{
  /* <div className='btn btn-light-primary fw-bolder w-100 mb-8'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/email/google-icon.svg')}
                className='h-20px me-3'
              />
              Invite Gmail Contacts
            </div> */
}

{
  /* <div className='separator d-flex flex-center mb-8'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>or</span>
            </div> */
}

{
  /* <textarea
              className='form-control form-control-solid mb-8'
              rows={3}
              placeholder='Type or paste emails here'
            ></textarea> */
}

{
  /* <div className='d-flex flex-stack'>
              <div className='me-5 fw-bold'>
                <label className='fs-6'>Adding Users by Team Members</label>
                <div className='fs-7 text-muted'>
                  If you need more info, please check budget planning
                </div>
              </div>

              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input className='form-check-input' type='checkbox' value='1' />

                <span className='form-check-label fw-bold text-muted'>Allowed</span>
              </label>
            </div> */
}
//       </div>
//     </div>
//   </div>
// </div> */}
