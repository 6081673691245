import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { HeaderWrapper } from './components/header'
import { toastr } from 'react-redux-toastr'
import { ScrollTop } from './components/scroll-top'
import { Content } from './components/content'
import { FooterWrapper } from './components/footer'
import { Sidebar } from './components/sidebar'
import { DrawerMessenger, ThemeModeProvider } from '../partials'
import { PageDataProvider } from './core'
import { reInitMenu } from '../helpers'
import { PageNavbarMain } from '../../_metronic/layout/components/header/PageNavbarMain'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

const MasterLayout = ({ newChatSocket, setLimit, limit }: any) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const location = useLocation()
  // const [sound] = useState(new Audio(process.env.REACT_APP_RINGTONE_URL));

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  const getNotificationList = () => {
    newChatSocket.emit('get_notification_list', {
      user_id: Number(localStorage.getItem('user_id')),
      page: 1,
      limit,
      platform: 'bancial',
    })
  }

  useEffect(() => {
    getNotificationList()
    newChatSocket.on('get_notification_list_succ', (data: any) => {
      localStorage.setItem("unread_count", data.unread_count)
      dispatch({ type: 'GET_NOTIFICATIONS', payload: data })
    })
    newChatSocket.on('recieve_notification', (data: any) => {
      getNotificationList()

      if (data.type) {
        const showNotification = () => {
          // sound.muted = false;
          // sound.autoplay = true;
          // sound.play();
        };
        showNotification();
      }

    })
    newChatSocket.on('read_notifications_succ', (data: any) => {
      getNotificationList()
    })
    newChatSocket.on('clear_all_notifications_succ', (data: any) => {
      getNotificationList()
      toastr.success(intl.formatMessage({ id: "TEXT.SUCCESS" }), intl.formatMessage({ id: 'TEXT.NOTIFICATION_CLEARED' }))
    })
    newChatSocket.on('delete_notification_succ', (data: any) => {
      getNotificationList()
      toastr.success(intl.formatMessage({ id: "TEXT.SUCCESS" }), intl.formatMessage({ id: 'TEXT.NOTIFICATION_DELETED' }))
    })
  }, [newChatSocket])


  return (
    <>
      <PageDataProvider>
        <ThemeModeProvider>
          <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
            <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
              <HeaderWrapper newChatSocket={newChatSocket} setLimit={setLimit} limit={limit} />
              <div className='app-wrapper flex-column flex-row-fluid overflow-hidden' id='kt_app_wrapper'>
                <Sidebar />
                <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                  <div className='d-flex flex-column flex-column-fluid main_content'>
                    <PageNavbarMain />
                    <Content>
                      <Outlet />
                    </Content>
                  </div>
                  <FooterWrapper />
                </div>
              </div>
            </div>
          </div>

          {/* begin:: Drawers */}
          <DrawerMessenger />
          {/* end:: Drawers */}
          <ScrollTop />
        </ThemeModeProvider>
      </PageDataProvider>
    </>
  )
}

export { MasterLayout }
