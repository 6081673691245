import {useContext, useState} from 'react'
import {Modal, ModalBody, ModalFooter} from 'react-bootstrap'
import {ChatContext} from '../../../../app/modules/apps/utils/ChatProvider'
import {useIntl} from 'react-intl'

/* eslint-disable jsx-a11y/anchor-is-valid */
let Dropdown4 = (props: any) => {
  const intl = useIntl()
  const {selectedChatRoom, blockUser, unBlockUser, deleteChat, deleteMessage} =
    useContext(ChatContext)
  const handleClose = () => props.setIsMenuOpen(false)
  const handleShow = () => props.setIsMenuOpen(true)

  const deleteMessageAction = (delete_type: string) => {
    deleteMessage(props?.selectedMessageId, delete_type)
    props?.setSelectedMessageId('')
    props.setIsMenuOpen(false)
    handleClose()
  }

  return (
    <>
      {/* delete modal */}
      <Modal show={props.isMenuOpen} onHide={handleClose}>
        <ModalBody> {intl.formatMessage({id: 'TEXT.DELETE_THIS_MSG'})}</ModalBody>
        <ModalFooter>
          <button
            className='btn btn-secondary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => handleClose()}
          >
            {intl.formatMessage({id: 'BTN.CANCEL'})}
          </button>

          <button
            className='btn btn-light-primary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => deleteMessageAction('for_me')}
          >
            {intl.formatMessage({id: 'BTN.FOR_ME'})}
          </button>
          {!props?.isForMe && (
            <button
              className='btn btn-light-primary theme-same-button'
              type='button'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_search_users'
              onClick={() => deleteMessageAction('for_everyone')}
            >
              {intl.formatMessage({id: 'BTN.FOR_EVERYONE'})}
            </button>
          )}
        </ModalFooter>
      </Modal>
    </>
  )
}

export {Dropdown4}
