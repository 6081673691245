import { useIntl } from 'react-intl'
import { SidebarMenuItem } from './SidebarMenuItem'
import Advertisements from '../../ads/advertisement'

const SidebarMenuMain = () => {
  const intl = useIntl()
  return (
    <>
      <SidebarMenuItem
        to={process.env.REACT_APP_BANANRY_URL + 'music'}
        title={intl.formatMessage({ id: 'MENU.MUSIC' })}
        icon='/media/bananry/music.svg'
        fontIcon='bi-app-indicator'
        type='outer'
      />
      <SidebarMenuItem
        to={process.env.REACT_APP_BANANRY_URL + 'video-music'}
        title={intl.formatMessage({ id: 'MENU.MUSIC_VIDEOS' })}
        icon='/media/bananry/music-videos.svg'
        fontIcon='bi-app-indicator'
        type='outer'
      />
      <SidebarMenuItem
        to={process.env.REACT_APP_EVENT_URL + 'event-list.php?u=' + localStorage.getItem('token')}
        title={intl.formatMessage({ id: 'MENU.EVENTS' })}
        icon='/media/bananry/events.svg'
        fontIcon='bi-app-indicator'
        type='outer'
      />
      <SidebarMenuItem
        to='/'
        title={intl.formatMessage({ id: 'MENU.BANCIAL' })}
        icon='/media/bananry/bancial.svg'
        fontIcon='bi-app-indicator'
        type='inner'
      />
      <SidebarMenuItem
        to={
          process.env.REACT_APP_BANANRY_URL + '' + localStorage.getItem('user_name') + '/promoter'
        }
        title={intl.formatMessage({ id: 'MENU.PRODUCERS' })}
        icon='/media/bananry/promoters.svg'
        fontIcon='bi-app-indicator'
        type='outer'
      />
      <div className='border-bottom-1 border border-solid border-secondary'></div>
      <div className='min-h-475px mx-5 w-auto'>
        <Advertisements />
      </div>
    </>
  )
}

export { SidebarMenuMain }
