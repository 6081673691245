/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from 'react-redux'
import { formatRelativeTime } from '../../../../app/utils'
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers/components/KTSVG'
const parse = require('html-react-parser')

const HeaderNotificationsMenu = ({ setLimit, limit, newChatSocket }: any) => {
  const intl = useIntl()
  const { notifications } = useSelector((state: any) => state.notification)
  const [show, setShow] = useState(false)
  const [selctedNotificationId, setSelectedNotificationId] = useState()
  const [modalType, setModelType] = useState('')
  const handleClose = () => setShow(false)

  const clear_all_notifications = () => {
    newChatSocket.emit('clear_all_notifications', {
      user_id: Number(localStorage.getItem('user_id')),
    })
  }

  const delete_notification = (e: any, notification_id: any) => {
    e.preventDefault()
    setShow(true)
    setSelectedNotificationId(notification_id)
    setModelType('delete_notification')
  }

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
        data-kt-menu='true'
      >
        <div className='d-flex flex-stack bgi-no-repeat rounded-top bg bg-primary'>
          <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
            {intl.formatMessage({ id: 'TEXT.NOTIFICATIONS' })}
          </h3>
          <div className='mt-10 mb-6 me-4'>
            {notifications?.notifications?.length > 0 && (
              <button
                type='button'
                className='btn btn-sm btn-light btn-active-light-primary me-1'
                onClick={(e: any) => {
                  e.preventDefault()
                  setShow(true)
                  setModelType('clear_notification')
                }}
              >
                {intl.formatMessage({ id: 'BTN.CLEAR_ALL' })}
              </button>
            )}
          </div>
        </div>
        <div className='tab-content'>
          <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
            <div className='scroll-y mh-325px'>
              {notifications?.notifications?.length > 0 ? (
                notifications?.notifications.map((n: any, index: any) => (
                  <div key={index}>
                    <a
                      href={n.url}
                      className='d-flex align-items-center py-4 px-8 pe-4 bg bg-hover-secondary'
                    >
                      <div className='flex-grow-1'>
                        <span className='text-gray-800 fw-bold fs-6'>
                          {n.title}
                        </span>
                        <span className='text-muted fw-semibold d-block'>
                          {n.message ? parse(n.message) : ''}
                        </span>
                      </div>
                      <div className='badge badge-light mx-2'>
                        {formatRelativeTime(n.created_at)}
                      </div>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary'
                        onClick={(e) => delete_notification(e, n.id)}
                      >
                        <KTSVG
                          path='/media/icons/duotune/bancial/trash.svg'
                          className='svg-icon-7'
                        />
                      </button>
                    </a>
                    <div className='separator separator-solid-black'></div>
                  </div>
                ))
              ) : (
                <div className='px-8 py-5'>
                  {intl.formatMessage({ id: 'TEXT.NOTIFICATION_NOT_FOUND' })}
                </div>
              )}
            </div>

            {/* <div className='py-3 text-center border-top'>
            <Link
              to='#'
              onClick={() => setLimit((prev: any) => Number(prev) + 10)}
              className='btn btn-active-color-primary btn-color-primary'
              aria-disabled={limit > 30 ? false : true}
            >
              View More
              <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
            </Link>
          </div> */}
          </div>
        </div>
      </div>
      {/* delete modal */}
      <Modal show={show} onHide={handleClose}>
        <ModalBody>
          {intl.formatMessage({ id: 'TEXT.ARE_YOU_SURE' })}{' '}
          {modalType == 'delete_notification'
            ? intl.formatMessage({ id: 'TEXT.DELETE_THIS' }) + " " + intl.formatMessage({ id: 'TEXT.NOTIFICATION' }).toLowerCase()
            : intl.formatMessage({ id: 'BTN.CLEAR_ALL' }).toLowerCase() + " " + intl.formatMessage({ id: 'TEXT.NOTIFICATIONS' }).toLowerCase()}
          ?
        </ModalBody>
        <ModalFooter>
          <button
            className='btn btn-secondary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => handleClose()}
          >
            {intl.formatMessage({ id: 'BTN.CANCEL' })}
          </button>

          <button
            className='btn btn-light-primary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => {
              modalType == 'delete_notification'
                ? newChatSocket.emit('delete_notification', {
                  notification_id: Number(selctedNotificationId),
                })
                : clear_all_notifications()
              handleClose()
            }}
          >
            {intl.formatMessage({ id: 'BTN.CONFIRM' })}
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export { HeaderNotificationsMenu }
