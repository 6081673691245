/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useState} from 'react'
import {ChatContext} from '../../../../app/modules/apps/utils/ChatProvider'
import {InviteUsers} from '../../modals/invite-users/InviteUsers'
import {RemoveUser} from '../../modals/remove-user/removeUser'
import {Modal, ModalBody, ModalFooter} from 'react-bootstrap'
import {useIntl} from 'react-intl'

/* eslint-disable jsx-a11y/anchor-is-valid */
const Dropdown2 = (props: any) => {
  const intl = useIntl()
  const {userId, clearChat, selectedChatRoom, deleteChat, leftChat} = useContext(ChatContext)
  const [chatModalOpen, setchatModalOpen] = useState(false)
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [leftChatModal, setLeftChatModal] = useState(false)
  const [action, setAction] = useState('')

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleLeftChatClose = () => {
    setAction('')
    setLeftChatModal(false)
  }
  const handleLeftChatShow = (string: any) => {
    setAction(string)
    setLeftChatModal(true)
  }

  const deleteChatAction = () => {
    deleteChat(selectedChatRoom?.id, 'for_everyone')
    handleClose()
  }

  const leftChatAction = () => {
    if (action == 'delete') {
      deleteChat(selectedChatRoom?.id, 'for_everyone')
    } else {
      leftChat()
    }

    handleClose()
  }

  return (
    <>
      <div
        className={`${
          props.isMenuOpen ? 'd-block position-absolute r-0' : 'd-none'
        } menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px`}
      >
        {userId == selectedChatRoom?.group_admin && (
          <>
            <div className='separator mb-3 opacity-75'></div>
            <div className='menu-item p-3' onClick={() => setchatModalOpen(true)}>
              <a
                href='#'
                className='menu-link px-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_invite_friends'
              >
                {intl.formatMessage({id: 'TEXT.ADD_MEMBERS'})}
              </a>
            </div>
          </>
        )}
        <div className='separator  opacity-75'></div>

        {userId == selectedChatRoom?.group_admin && (
          <div className='menu-item p-3' onClick={() => setRemoveModalOpen(true)}>
            <a
              href='#'
              className='menu-link px-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_remove_friends'
            >
              {intl.formatMessage({id: 'TEXT.REMOVE_MEMBERS'})}
            </a>
          </div>
        )}
        <div className='separator  opacity-75'></div>

        {/* <div className='menu-item p-3' onClick={() => clearChat()}>
          <a
            href='#'
            className='menu-link px-3'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
            Clear Chat
          </a>
        </div>
        <div className='separator  opacity-75'></div> */}

        <div className='separator  opacity-75'></div>

        {userId == selectedChatRoom?.group_admin ? (
          <div className='menu-item p-3' onClick={() => handleLeftChatShow('delete')}>
            <a href='#' className='menu-link px-3'>
              {intl.formatMessage({id: 'TEXT.LEAVE_DELETE_GROUP'})}
            </a>
          </div>
        ) : (
          <div className='menu-item p-3' onClick={() => handleLeftChatShow('leave')}>
            <a href='#' className='menu-link px-3'>
              {intl.formatMessage({id: 'TEXT.LEAVE_GROUP'})}
            </a>
          </div>
        )}
        <div className='separator  opacity-75'></div>
        <InviteUsers
          chatModalOpen={chatModalOpen}
          setchatModalOpen={setchatModalOpen}
          // setSelectedChat={setSelectedChat}
          user_list={function (arg0: {
            page: number
            limit: number
            user_id: string | null
            search?: string | undefined
          }) {
            throw new Error('Function not implemented.')
          }}
        />
        <RemoveUser chatModalOpen={removeModalOpen} setchatModalOpen={setRemoveModalOpen} />
      </div>

      {/* Left the room modal */}
      <Modal show={leftChatModal} onHide={handleLeftChatClose}>
        <ModalBody>
          {intl.formatMessage({id: 'TEXT.ARE_YOU_SURE'})}{' '}
          {action == intl.formatMessage({id: 'TEXT.DELETE'})
            ? intl.formatMessage({id: 'TEXT.LEAVE_DELETE'})
            : intl.formatMessage({id: 'TEXT.LEAVE'})}{' '}
          {intl.formatMessage({id: 'TEXT.GROUP'})}?
        </ModalBody>
        <ModalFooter>
          <button
            className='btn btn-secondary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => handleLeftChatClose()}
          >
            {intl.formatMessage({id: 'BTN.CANCEL'})}
          </button>
          <button
            className='btn btn-primary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => leftChatAction()}
          >
            {intl.formatMessage({id: 'BTN.CONFIRM'})}
          </button>
        </ModalFooter>
      </Modal>
      {/* delete modal */}
      <Modal show={show} onHide={handleClose}>
        <ModalBody>{intl.formatMessage({id: 'TEXT.CHAT_DELETE'})}</ModalBody>
        <ModalFooter>
          <button
            className='btn btn-secondary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => handleClose()}
          >
            {intl.formatMessage({id: 'BTN.CANCEL'})}
          </button>
          <button
            className='btn btn-light-primary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => deleteChatAction()}
          >
            {intl.formatMessage({id: 'BTN.FOR_ME'})}
          </button>
          <button
            className='btn btn-light-primary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => deleteChatAction()}
          >
            {intl.formatMessage({id: 'BTN.FOR_EVERYONE'})}
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export {Dropdown2}
