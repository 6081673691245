/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { ThemeModeSwitcher } from '../theme-mode/ThemeModeSwitcher'
import { useIntl } from 'react-intl'
import * as USERACTIONS from '../../../../store/User/Actions'
import { connect } from 'react-redux'

type Props = {
  isUserMenu: any
}

let HeaderUserMenu: FC<Props> = (props: any) => {
  const intl = useIntl()
  let signout = () => {
    props.sign_out({ do: 'unsetSession' })
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-state-bg menu-state-primary py-4 fs-7 w-225px'
      data-kt-menu='true'
    >
      {props?.isUserMenu ? (
        <>
          <div className='menu-item px-2 menu-sub'>
            <a href={process.env.REACT_APP_BANANRY_URL + 'settings'} className='menu-link h-30px'>
              {intl.formatMessage({ id: 'MENU.PROFILE' })}
            </a>
          </div>
          <div className='menu-item px-2 menu-sub'>
            <a href={process.env.REACT_APP_BANANRY_URL + 'upload'} className='menu-link h-30px'>
              {intl.formatMessage({ id: 'MENU.UPLOADS' })}
            </a>
          </div>
          <div className='menu-item px-2 menu-sub'>
            <a
              href={process.env.REACT_APP_BANANRY_URL + 'video-recording/my_record_video'}
              className='menu-link h-30px'
            >
              {intl.formatMessage({ id: 'MENU.RECORD_VIDEOS' })}
            </a>
          </div>
          <div className='menu-item px-2 menu-sub'>
            <a
              href={process.env.REACT_APP_BANANRY_URL + 'artist-management'}
              className='menu-link h-30px'
            >
              {intl.formatMessage({ id: 'MENU.PRODUCER_MANAGER' })}
            </a>
          </div>
          <div className='menu-item px-2 menu-sub'>
            <a
              href={process.env.REACT_APP_BANANRY_URL + 'advertbanners'}
              className='menu-link h-30px'
            >
              {intl.formatMessage({ id: 'MENU.ADS_MANAGER' })}
            </a>
          </div>
          <div className='menu-item px-2 menu-sub'>
            <a
              href={process.env.REACT_APP_BANANRY_URL + 'myplaylists'}
              className='menu-link h-30px'
            >
              {intl.formatMessage({ id: 'MENU.PLAYLIST' })}
            </a>
          </div>
          <div className='menu-item px-2 menu-sub'>
            <a
              href={process.env.REACT_APP_BANANRY_URL + 'favorites'}
              className='menu-link h-30px'
            >
              {intl.formatMessage({ id: 'MENU.FAVSONGS' })}
            </a>
          </div>
          <div className='separator separator-solid mx-lg-5 mb-2 mt-2'></div>
          <div className='menu-item px-2 menu-sub'>
            <div className='menu-link h-30px' onClick={signout}>
              {intl.formatMessage({ id: 'MENU.SIGN_OUT' })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='menu-item px-2 menu-sub'>
            <div className='menu-link h-30px'>
              <ThemeModeSwitcher />
            </div>
          </div>
          <div className='menu-item px-2 menu-sub'>
            <a
              href={process.env.REACT_APP_BANANRY_URL + 'settings/feedback'}
              className='menu-link h-30px'
            >
              {intl.formatMessage({ id: 'MENU.FEEDBACK_HELP' })}
            </a>
          </div>
          <div className='menu-item px-2 menu-sub'>
            <a
              href={process.env.REACT_APP_BANANRY_URL + 'settings/subscription'}
              className='menu-link h-30px'
            >
              {intl.formatMessage({ id: 'MENU.PRODUCER_SUBSCRIPTION' })}
            </a>
          </div>
          <div className='menu-item px-2 menu-sub'>
            <a
              href={process.env.REACT_APP_BANANRY_URL + 'settings/advertsubscription'}
              className='menu-link h-30px'
            >
              {intl.formatMessage({ id: 'MENU.ADS_SUBSCRIPTION' })}
            </a>
          </div>
          <div className='menu-item px-2 menu-sub'>
            <a
              href={process.env.REACT_APP_BANANRY_URL + 'settings/eventsubscription'}
              className='menu-link h-30px'
            >
              {intl.formatMessage({ id: 'MENU.EVENT_SUBSCRIPTION' })}
            </a>
          </div>
          <div className='menu-item px-2 menu-sub'>
            <a
              href={process.env.REACT_APP_BANANRY_URL + 'settings/preferences'}
              className='menu-link h-30px'
            >
              {intl.formatMessage({ id: 'MENU.PREFERENCES' })}
            </a>
          </div>
        </>
      )}
    </div>
  )
}

interface RootState {
  user: []
}

const mapStateToProps = (state: RootState) => ({
  userStore: state.user,
})

const mapDispatchToProps = (dispatch: any) => ({
  sign_out: (data: any) => dispatch(USERACTIONS.sign_out(data)),
})

HeaderUserMenu = connect(mapStateToProps, mapDispatchToProps)(HeaderUserMenu)

export { HeaderUserMenu }
