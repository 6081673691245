import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'

const Error401: FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className="mb-3">
        <div className="fw-bolder fs-2hx text-gray-900 mb-4">401 {intl.formatMessage({ id: 'INFO.UNAUTHORIZED' })}</div>
      </div>
      {/* begin::Text */}
      <h1 className="fw-bolder fs-2 text-gray-900 mb-10">{intl.formatMessage({ id: 'INFO.TOKEN_INVALID' })}</h1>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className='mb-8'>
        <img
          src={toAbsoluteUrl('/media/auth/error.svg')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/auth/error.svg')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className='mb-0'>
        <a href={process.env.REACT_APP_BANANRY_URL || "/"} className='btn btn-sm btn-primary'>
          {intl.formatMessage({ id: 'BTN.RETURN_HOME' })}
        </a>
      </div>
      {/* end::Link */}
    </>
  )
}

export { Error401 }
