import React, { createContext, useState, useEffect, useReducer } from 'react'
import { toastr } from 'react-redux-toastr'
import { useDispatch } from 'react-redux'
import { chatLoaderChange, loaderChange } from '../../../../store/Loader/Reducer'
import { useIntl } from 'react-intl'

export const ChatContext = createContext({
  userId: {},
  chatSocket: {} as any,
  userList: [] as any,
  activeRoomIds: '',
  receivedMessage: {},
  chatHistory: null as any,
  groupUsers: null as any,
  selectedChatRoom: {} as any,
  CHATLIST_ACTIONS: {} as any,
  chatList: {
    chatList: [],
    groupChatList: [],
    isLoading: false,
  },
  setSelectedChatRoom: (values: any) => { },
  setUserList: (values: any) => { },
  setChatHistory: (values: any) => { },
  dispatchChatList: ({ type, payload }: any) => { },
  switchRooms: (values: any) => { },
  clearChat: () => { },
  leftChat: () => { },
  getGroupUsers: (roomId: number) => { },
  handleGroupChatListChange: (message: string) => { },
  handleChange: (message: string) => { },
  createPrivateChat: (otherUserId: number) => { },
  getUserListEmit: (search: string, page: number) => { },
  addMember: (id: number) => { },
  deleteMember: (deleteUser: number) => { },
  createGroupChat: (groupName: string, groupMembers: string) => { },
  deleteChat: (chat_id: number, delete_type: string) => { },
  blockUser: (chat_id: number, user_id: number) => { },
  unBlockUser: (chat_id: number, user_id: number) => { },
  sendMessage: (message: string, room_name: string, users: any[]) => { },
  readBy: (userId: number, chatID: number, senderId: number) => { },
  groupReadBy: (userId: number, chatID: number) => { },
  deleteMessage: (message_id: number, delete_type: string) => { },
  chatlist: (userId: number, search: string) => { },
  clearNotifications: () => { },

})

const CHATLIST_ACTIONS = {
  SET_MORECHATS: 'SET_MORECHATS',
  SET_GROUP_CHAT_LIST: 'SET_GROUP_CHAT_LIST',
}

const chatListReducer = (state: any, { type, payload }: any) => {
  switch (type) {
    case CHATLIST_ACTIONS.SET_MORECHATS: {
      return {
        ...state,
        chatList: [...payload],
      }
    }

    case CHATLIST_ACTIONS.SET_GROUP_CHAT_LIST: {
      return {
        ...state,
        groupChatList: [...payload],
      }
    }
    default:
      return state
  }
}

const ChatProvider = ({ chatSocket, userId, children, onConnectionError, onReceiveMessage }: any) => {
  const intl = useIntl()
  const [chatList, dispatchChatList] = useReducer(chatListReducer, {
    chatList: [],
    groupChatList: [],
    isLoading: false,
  })
  let dispatch = useDispatch()
  // const selectedChatRoom = jsonString ? JSON.parse(jsonString) : {}

  const [activeRoomId, setActiveRoomId] = useState('')
  const [receivedMessage, setReceivedMessage] = useState<any>()
  const [selectedChatRoom, setSelectedChatRoom] = useState<any>()
  const [chatHistory, setChatHistory] = useState<any>([])
  const [userList, setUserList] = useState<any>([])
  const [groupUsers, setGroupUsers] = useState<any>([])

  let chatlist = (userId: number, search: string) => {
    // dispatch(loaderChange(true))
    chatSocket.emit('chat_list', { user_id: userId, page: 1, limit: 100, search: search })
  }

  const getGroupChatList = (user_id: number, search: string) => {
    // dispatch(loaderChange(true))
    chatSocket.emit('group_chat_list', { user_id: user_id, page: 1, limit: 100, search: search })
  }

  let readBy = (userId: number, chatID: number, senderId: number) => {
    chatSocket.emit('read_by', { user_id: userId, chat_id: chatID, sender_user_id: senderId })
  }

  let groupReadBy = (userId: number, chatID: number) => {
    chatSocket.emit('group_read_by', { user_id: userId, chat_id: chatID })
  }

  const switchRooms = (values: any) => {
    setActiveRoomId(values)
    setReceivedMessage({})
  }

  const sendMessage = (messageContent: string, roomName: string, users: any = []) => {
    dispatch(chatLoaderChange(true))
    if (messageContent && messageContent.trim().length > 0) {
      let newMessage = {
        chat_id: selectedChatRoom?.id,
        user_id: userId,
        content: messageContent,
        room: roomName,
        users: users,
      }

      chatSocket.emit('send_message', newMessage)
    }
  }

  const clearChat = () => {
    chatSocket.emit('clear_chat', {
      chat_id: selectedChatRoom?.id,
      room: selectedChatRoom?.room_name,
    })
  }

  const leftChat = () => {
    chatSocket.emit('leave_group', {
      chat_id: Number(selectedChatRoom?.id),
      user_id: Number(userId),
    })
  }

  const getUserListEmit = (search: string, page: number) => {
    chatSocket.emit('user_list', { page: page, limit: 10, user_id: userId, search: search })
  }

  const createPrivateChat = (otherUserId: number) => {
    dispatch(chatLoaderChange(true))
    chatSocket.emit('create_private_chat', {
      page: 1,
      limit: 100,
      user_id: userId,
      other_user_id: otherUserId,
    })
  }

  const deleteMember = (deleteUser: number) => {
    chatSocket.emit('delete_member', { user_id: deleteUser, chat_id: selectedChatRoom?.id })
    toastr.success(intl.formatMessage({ id: "TEXT.SUCCESS" }), intl.formatMessage({ id: 'TEXT.MEMBER_DELETED' }))
  }

  const addMember = (add_user: number) => {
    chatSocket.emit('add_member', { users: add_user, chat_id: selectedChatRoom?.id })
    toastr.success(intl.formatMessage({ id: "TEXT.SUCCESS" }), intl.formatMessage({ id: 'TEXT.MEMBER_ADDED' }))
  }

  const getGroupUsers = (roomId: number) => {
    chatSocket.emit('group_chat_users', { chat_id: roomId })
  }

  const createGroupChat = (groupName: string, groupMembers: string) => {
    dispatch(chatLoaderChange(true))
    console.log("create_group_chat", {
      chat_name: groupName,
      users: groupMembers,
      group_admin: userId,
      created_by: userId,
    })
    chatSocket.emit('create_group_chat', {
      chat_name: groupName,
      users: groupMembers,
      group_admin: userId,
      created_by: userId,
    })
  }

  const blockUser = (chat_id: number, user_id: number) => {
    dispatch(chatLoaderChange(true))
    chatSocket.emit('block_chat', {
      chat_id,
      user_id: userId,
    })
  }

  const unBlockUser = (chat_id: number, user_id: number) => {
    dispatch(chatLoaderChange(true))
    chatSocket.emit('unblock_chat', {
      chat_id,
      user_id: userId,
    })
  }

  const deleteChat = (chat_id: number, delete_type: string) => {
    chatSocket.emit('delete_chat', {
      chat_id: chat_id,
      user_id: Number(userId),
      delete_type: delete_type,
    })
  }

  const deleteMessage = (message_id: number, delete_type: string) => {
    chatSocket.emit('delete_message', {
      message_id: message_id,
      user_id: userId,
      delete_type: delete_type,
    })
  }

  useEffect(() => {
    if (
      window.location.search &&
      window.location.search.split('=').length > 1 &&
      chatSocket &&
      userId
    ) {
      let otherUserId = window.location.search.split('=')[1]
      let page = window.location.search.split('=')[0]
      if (otherUserId.includes('#')) {
        otherUserId = otherUserId.split('#')[0]
      }
      if (page.includes('chatPage')) {
        if (otherUserId) {
          createPrivateChat(Number(otherUserId))
        }
      } else {
        let filter =
          chatList.groupChatList.length > 0 &&
          chatList.groupChatList.find((group: any) => group.id == Number(otherUserId))

        setSelectedChatRoom(filter)
      }
    }
  }, [window.location.search, chatList.groupChatList])

  useEffect(() => {
    if (userId && chatSocket) {
      // chatSocket.on('connect', () => {
      //   if (chatSocket.connected) {
      //     // console.log('socket is connected successfully')

      //     //code for connecting users to all rooms
      //     chatSocket.emit('connect_user', {
      //       user_id: Number(userId),
      //     })

      //     chatlist(userId, '')
      //     getGroupChatList(userId, '')

      //     chatSocket.io.on('error', (error: any) => {
      //       console.log('Chat provider Socket connection error.', error)
      //     })
      //   }
      // })
    }
    return () => {
      // chatSocket.disconnect();
    }
  }, [userId, chatSocket, onConnectionError])

  const getMessages = (chatId: number) => {
    if (chatId) {
      // dispatch(loaderChange(true))
      chatSocket.emit('get_messages', { chat_id: chatId, user_id: userId, page: 1, limit: 30 })
    }
  }

  const handleChange = async (target: any) => {
    chatlist(userId, target)
  }

  const handleGroupChatListChange = async (target: any) => {
    getGroupChatList(userId, target)
  }

  useEffect(() => {
    if (selectedChatRoom && Object.values(selectedChatRoom).length && selectedChatRoom?.id) {
      getMessages(selectedChatRoom?.id)
    }
  }, [selectedChatRoom?.id])

  useEffect(() => {
    chatSocket.on('receive_clear_chat', (data: any) => {
      chatlist(userId, '')
      setChatHistory([])
      dispatch(chatLoaderChange(false))
    })

    chatSocket.on('create_private_chat_succ', (data: any) => {
      localStorage.setItem('roomId', JSON.stringify(data))
      chatlist(userId, '')
      setSelectedChatRoom(data)
      dispatch(chatLoaderChange(false))
      const element = document.getElementById(`chatList-${data.id}`);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }, 1000)
      }
      // if (window.location.search && window.location.search.split('=').length > 1) {
      //   window.location.replace('/chat/private-chat')
      // }
    })

    chatListAction()
    groupChatListAction()

    chatSocket.on('read_by_success', (data: any) => {
      chatlist(userId, '')
      dispatch(chatLoaderChange(false))
    })

    chatSocket.on('group_read_by_succ', (data: any) => {
      getGroupChatList(userId, '')
      dispatch(chatLoaderChange(false))
    })

    chatSocket.on('receive_user_list', (data: any) => {
      setUserList(data)
      dispatch(chatLoaderChange(false))
    })

    chatSocket.on('receive_get_messages', (data: any) => {
      setChatHistory(data)
      // dispatch(loaderChange(false))
      dispatch(chatLoaderChange(false))
    })

    chatSocket.on('block_chat_success', (data: any) => {
      localStorage.setItem('roomId', JSON.stringify(data))
      setSelectedChatRoom(data)
      dispatch(chatLoaderChange(false))
      chatlist(userId, '')
    })

    chatSocket.on('add_member_receive', (data: any) => {
      setSelectedChatRoom(data)
      getGroupChatList(userId, '')
      getGroupUsers(data?.id)
      dispatch(chatLoaderChange(false))
    })

    chatSocket.on('delete_member_receive', (data: any) => {
      setSelectedChatRoom(data)
      getGroupChatList(userId, '')
      getGroupUsers(data?.id)
      dispatch(chatLoaderChange(false))
    })

    chatSocket.on('unblock_chat_success', (data: any) => {
      localStorage.setItem('roomId', JSON.stringify(data))
      setSelectedChatRoom(data)
      dispatch(chatLoaderChange(false))
      chatlist(userId, '')
    })

    chatSocket.on('delete_chat_succ', (data: any) => {
      chatlist(userId, '')
      getGroupChatList(userId, '')
      localStorage.removeItem('roomId')
      setSelectedChatRoom(null)
      dispatch(chatLoaderChange(false))
    })

    chatSocket.on('delete_message_succ', (data: any) => {
      getMessages(JSON.parse(localStorage.getItem('roomId') || '')?.id)
    })

    chatSocket.on('leave_group_succ', (data: any) => {
      getGroupChatList(userId, '')
      localStorage.removeItem('roomId')
      setSelectedChatRoom(null)
      dispatch(chatLoaderChange(false))
    })

    chatSocket.on('join-call-recive', (data: any) => {
      console.log('join-call-recive', data)
      chatlist(userId, '')
      getGroupChatList(userId, '')
      localStorage.setItem('roomId', JSON.stringify(data))
      setSelectedChatRoom(data)
    })

    chatSocket.on('terminate-call-recive', (data: any) => {
      console.log('terminate-call-recive', data)
      chatlist(userId, '')
      getGroupChatList(userId, '')
      localStorage.setItem('roomId', JSON.stringify(data))
      setSelectedChatRoom(data)
    })
  }, [chatSocket])

  useEffect(() => {
    chatSocket.on('receive_message', (data: any) => {
      const localRoom = localStorage.getItem('roomId') as string
      const roomId = localRoom ? JSON.parse(localRoom) : null
      chatlist(userId, '')
      getGroupChatList(userId, '')
      if (Number(roomId?.id) !== data[0].chat) return
      setChatHistory((prev: any) => [
        ...prev,
        prev.findIndex((chat: any) => chat.id === data[0].id) === -1 ? data[0] : null,
      ])
      const senderId =
        roomId?.User[0].id === Number(userId) ? roomId?.User[1]?.id : roomId?.User[0]?.id
      setTimeout(() => {
        readBy(Number(userId), data[0].chat, senderId)
        groupReadBy(Number(userId), data[0].chat)
      }, 2000)
      dispatch(chatLoaderChange(false))
    })
  }, [chatSocket])

  const chatListAction = () => {
    chatSocket.on('receive_chat_list', (data: any) => {
      dispatchChatList({
        type: CHATLIST_ACTIONS.SET_MORECHATS,
        payload: data,
      })
      dispatch(chatLoaderChange(false))
    })
  }

  const groupChatListAction = () => {
    chatSocket.on('receive_group_chat_list', (data: any) => {
      console.log("receive_group_chat_list", data)
      dispatchChatList({
        type: CHATLIST_ACTIONS.SET_GROUP_CHAT_LIST,
        payload: data,
      })
      dispatch(chatLoaderChange(false))
    })
  }

  const contextProvider: any = {
    userId,
    chatSocket,
    activeRoomId,
    receivedMessage,
    CHATLIST_ACTIONS,
    chatList,
    selectedChatRoom,
    chatHistory,
    userList,
    groupUsers,
    createPrivateChat,
    setUserList,
    setChatHistory,
    setSelectedChatRoom,
    dispatchChatList,
    switchRooms,
    clearChat,
    sendMessage,
    getUserListEmit,
    handleChange,
    unBlockUser,
    addMember,
    deleteMember,
    createGroupChat,
    getGroupUsers,
    blockUser,
    deleteChat,
    readBy,
    leftChat,
    groupReadBy,
    handleGroupChatListChange,
    deleteMessage,
    chatlist
  }

  return <ChatContext.Provider value={contextProvider}>{children}</ChatContext.Provider>
}

export const ChatState = () => {
  return React.useContext(ChatContext)
}

export default ChatProvider
