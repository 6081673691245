/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import * as USERACTIONS from '../../../../store/User/Actions'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { ChatContext } from '../../../../app/modules/apps/utils/ChatProvider'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap'
import { getProfileName, getSenderProfileName } from '../../../../config/ChatLogics'
import { useIntl } from 'react-intl'

type Props = {
  setchatModalOpen?: any
  props?: any
  chatModalOpen?: any
}

let RemoveUser: FC<Props> = (props) => {
  const { deleteMember, getGroupUsers, groupUsers, selectedChatRoom, chatSocket } =
    useContext(ChatContext)
  const [users, setUsers] = useState<any[]>([])
  const [groupAdmin, setGroupAdmin] = useState(0)
  const [userListMore, setUserListMore] = useState(true)
  const intl = useIntl();

  useEffect(() => {
    const abortcontroller = new AbortController()
    if (!selectedChatRoom?.id) return
    getGroupUsers(selectedChatRoom?.id)
    return () => {
      abortcontroller.abort()
    }
  }, [selectedChatRoom?.id])

  useEffect(() => {
    chatSocket.on('receive_group_chat_users', (data: any) => {
      setGroupAdmin(data.group_admin)
      setUsers(data.User)
    })
  }, [chatSocket])

  const deleteGroupMember = async (userId: number) => {
    deleteMember(userId)
  }

  return (
    <Modal
      centered
      show={props.chatModalOpen}
      className='invite-member'
      onHide={() => props.setchatModalOpen(false)}
      backdrop='static'
      keyboard={false}
    >
      <ModalHeader>
        <div className='text-center mb-13 w-100'>
          {/* <h1 className='mb-3'>Invite a Friend</h1> */}
          <div className='d-flex justify-content-between'>
            <h1 className='mb-3 m-auto'>{intl.formatMessage({ id: "TEXT.REMOVE_MEMBERS" })}</h1>
            <h3 style={{ cursor: 'pointer' }} onClick={() => props.setchatModalOpen(false)}>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </h3>
          </div>

          <div className='text-muted fw-bold fs-5'>{intl.formatMessage({ id: "TEXT.REMOVE_MEMBER_TEXT" })}</div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className='mb-10'>
          <div className='fs-6 fw-bold mb-2' style={{ textAlign: 'center' }}>
            {intl.formatMessage({ id: "TEXT.PAL_LIST" })}
          </div>

          <div className='mh-300px scroll-y me-n7 pe-7' id='scrollableDivInvite'>
            {/* <InfiniteScroll
              dataLength={users?.length} //This is important field to render the next data
              next={fetchData}
              hasMore={userListMore}
              loader={
                <>
                  <br />
                  <h4 className='text-center'>Loading...</h4>
                </>
              }
              scrollableTarget='scrollableDivInvite'
              endMessage={
                <>
                  <br />
                  <p style={{ textAlign: 'center' }}>
                    <b>
                      {users?.length > 0 ? 'You have seen all records.' : 'No records found.'}
                    </b>
                  </p>
                </>
              }
            > */}

            {users?.map((user, i) => {
              return (
                <div
                  className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'
                  key={i}
                >
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-35px symbol-circle'>
                      {user?.profile_pic ? (
                        <a
                          href={`/profile/post/${user?.id}`}
                          className='symbol symbol-35px symbol-circle'
                        >
                          <img  src={user?.profile_pic} />
                        </a>
                      ) : (
                        <a
                          href={`/profile/post/${user?.id}`}
                          className='symbol symbol-35px symbol-circle'
                        >
                          <span className={`symbol-label bg-light-danger text-danger fw-bold`}>
                            {user?.name
                              ? `${getProfileName(user?.name)}`
                              : user?.email?.charAt(0)?.toUpperCase()}
                          </span>
                        </a>
                      )}
                    </div>

                    <div className='ms-5'>
                      <a
                        href={`/profile/post/${user?.id}`}
                        className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                      >
                        {user.name}
                      </a>
                      {/* <div className='fw-bold text-muted'>{user.email}</div> */}
                    </div>
                  </div>

                  <div className='ms-2 w-100px'>
                    {groupAdmin != user.id &&
                      <button
                        className='btn btn-sm btn-light-primary fw-bolder theme-same-button'
                        // id='kt_drawer_chat_toggle'
                        onClick={() => deleteGroupMember(user.id)}
                      >
                        {intl.formatMessage({ id: 'BTN.REMOVE' })}
                      </button>
                    }
                  </div>
                </div>
              )
            })}
            {/* </InfiniteScroll> */}
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export { RemoveUser }
