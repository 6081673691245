import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu } from '../../../partials'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { HeaderSearchMenu } from '../../../partials/layout/header-menus/HeaderSearchMenu'
const itemClass = 'ms-md-1 ms-lg-2'
const btnClass = 'btn btn-icon w-35px h-35px w-md-40px h-md-40px align-self-center'
const userAvatarClass = 'symbol-35px symbol-md-42px'

const Navbar = ({ newChatSocket, setLimit, limit }: any) => {
  const intl = useIntl()
  const [search, setSearch] = useState("")
  const userId = localStorage.getItem('user_id')
  const { notifications } = useSelector((state: any) => state.notification)
  const [profile, setProfile] = useState(toAbsoluteUrl('/media/avatars/blank.png'))
  const unread_count = Number(localStorage.getItem("unread_count"));

  useEffect(() => {
    if (localStorage.getItem('profile_pic')) {
      setProfile(localStorage.getItem('profile_pic') || toAbsoluteUrl('/media/avatars/blank.png'))
    }
  }, [])

  const handleReadAllNotification = () => {
    if (unread_count > 0) {
      newChatSocket.emit('read_notifications', { user_id: userId })
    }
  }

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <a href={process.env.REACT_APP_BANANRY_URL + "moods"} className="btn btn-sm btn-primary theme-same-button mood-title fs-7 me-md-4 mobile_recomm">{intl.formatMessage({ id: 'BTN.RECOMNDED_SONG' })}</a>
      </div>
      <div className={clsx('app-navbar-item align-items-stretch my-4 position-relative', itemClass)}>
        <div
          data-kt-menu-trigger="click"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div >
            <input type="text" name="search"
              autoComplete='off'
              className="form-control round_textbox" placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SEARCH' })} onChange={(e) => setSearch(e.target.value)}
            />
            <img
              src={toAbsoluteUrl('/media/icons/duotune/search/search.svg')}
              className='position-absolute translate-middle end-0 top-50 search_icon'
              alt=''
            />
          </div>
        </div>
        <HeaderSearchMenu search={search} setSearch={setSearch} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={clsx(btnClass,
            unread_count > 0 && 'mt-5'
          )}
        >
          <div onClick={handleReadAllNotification}>
            <KTSVG
              path='/media/icons/duotune/bancial/bell.svg'
              className={clsx("svg-icon-2 svg-icon-white",
                unread_count > 0 && 'mt-5'
              )}
            />
            {unread_count > 0 ? (
              <span className='notification-dot'>
                {unread_count > 99 ? '99+' : unread_count}
              </span>
            ) : (
              <span className='notification-dot'></span>
            )}
          </div>
        </div>
        <HeaderNotificationsMenu
          setLimit={setLimit}
          limit={limit}
          newChatSocket={newChatSocket}
        />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={profile} alt='' className='round-img' />
        </div>
        <HeaderUserMenu isUserMenu={true} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG
            path='/media/icons/duotune/bancial/setting.svg'
            className="svg-icon-2 svg-icon-white"
          />
        </div>
        <HeaderUserMenu isUserMenu={false} />
      </div>
    </div>
  )
}

export { Navbar }
