import {useIntl} from 'react-intl'
import {PageNavbar} from '../../../../_metronic/layout/components/header/PageNavbar'
import { KTSVG } from '../../../helpers';

const PageNavbarMain = () => {
  const intl = useIntl();
  return (
    <>
      <div className='app-navbar flex-shrink-0'>
        <div id='page'>
          <div id='page-nav'>
            <div className='outer'>
              <ul>
                <PageNavbar to='/feed' title={intl.formatMessage({ id: 'MENU.FEED' })} />
                <PageNavbar to='/social/user-suggestions' title={intl.formatMessage({ id: 'MENU.DISCOVER_PEOPLE' })} />
                <PageNavbar
                  to='/chat/private-chat'
                  title={intl.formatMessage({id: 'MENU.PRIVATE_CHAT'})}
                />
                <PageNavbar
                  to='/chat/group-chat'
                  title={intl.formatMessage({id: 'MENU.GROUP_CHAT'})}
                />
                <PageNavbar
                  to='/social/photos/my'
                  title={intl.formatMessage({id: 'MENU.PHOTOS'})}
                />
                <PageNavbar
                  to='/social/business-pals'
                  title={intl.formatMessage({id: 'MENU.BUSINESS_PALS'})}
                />
                <PageNavbar
                  to={'/profile/post/' + localStorage.getItem('user_id')}
                  title={intl.formatMessage({id: 'MENU.BANCIAL_PROFILE'})}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='app-container container-fluid mt-8'>
        <div className='alert alert-warning d-flex align-items-center p-5 mb-0'>
          <KTSVG
            path='/media/icons/duotune/bancial/infoCircle.svg'
            className='svg-icon-2x me-3 svg-icon-warning'
          />
          <div className='d-flex flex-column'>
            <p className='fw-bold mb-0'>{intl.formatMessage({id: 'INFO.MAIN_INFO'})}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export {PageNavbarMain}
