import React, { useEffect, useState } from 'react'
import { MentionsInput, Mention } from 'react-mentions'
import * as USERACTIONS from '../../../../store/User/Actions'
import { connect, useSelector } from 'react-redux'
import { filterBadWords } from '../../../../app/utils'

interface Props {
  class?: any
  id?: any
  name?: any
  placeholder?: any
  onChange?: any
  message?: any
  setMessage?: any
  setDisable?: any
  onKeyDown?: any
  setMentionUserIds?: any
  mentionUserIds?: any
  show?: any
  value?: any
  setValue?: any
}

let Mentions: React.FC<Props> = (props: any) => {
  const [users, setUsers]: any = useSelector((state: any) => [state.user.usernameList.record])
  let [index, setIndex] = useState(0)

  // when form submit that time value set to null
  useEffect(() => {
    const abortcontroller = new AbortController()
    if (props?.value == '') {
      props?.setValue('')
      setIndex(0)
    }

    return () => {
      abortcontroller.abort()
    }
  }, [props?.message])

  const mentionStyle = { backgroundColor: localStorage.getItem('kt_theme_mode_menu') == 'dark' ? '#75498a' : '#ddcde5', borderRadius: "4px", padding: "1px", width: "auto" }
  const mentionsInputStyle = {
    control: {
      backgroundColor: localStorage.getItem('kt_theme_mode_menu') == 'dark' ? '#26222e' : '#fff',
      fontSize: 14,
    },

    '&multiLine': {
      control: {
        fontFamily: '-apple-system, BlinkMacSystemFont, Roboto, Arial, Helvetica, sans-serif',
        minHeight: 60,
      },
      highlighter: {
        padding: 9,
        border: '1px solid transparent',
      },
      input: {
        padding: 9,
        border: '1px solid silver',
        color: localStorage.getItem('kt_theme_mode_menu') == 'dark' ? '#fff' : '#470069'
      },
    },

    '&singleLine': {
      display: 'inline-block',
      width: 180,

      highlighter: {
        padding: 1,
        border: '1px inset transparent',
      },
      input: {
        padding: 1,
        border: '1px solid silver',
        color: localStorage.getItem('kt_theme_mode_menu') == 'dark' ? '#fff' : '#470069'
      },
    },

    suggestions: {
      list: {
        backgroundColor: localStorage.getItem('kt_theme_mode_menu') == 'dark' ? '#151521' : 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 16,
        maxHeight: '200px',
        color: localStorage.getItem('kt_theme_mode_menu') == 'dark' ? '#fff' : '#470069',
        overflowY: 'auto',
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#ddcde5',
          color: '#470069',
        },
      },
    },
  }

  function renderSuggestion(
    suggestion: any
  ) {
    return <div className='d-flex align-items-center'>
      <div
        className='symbol symbol-30px me-5'
      >
        <img
          src={
            suggestion?.profile_pic
              ? suggestion?.profile_pic
              : '/media/avatars/blank.png'
          }
          className=''
          alt=''
        />
      </div>
      <div className='flex-grow'>
        <p className='fs-6 fw-bold align-items-center d-inline '
        >
          {suggestion.display}
        </p>

      </div>
    </div>
  }

  return (
    <>
      <MentionsInput
        placeholder={props.placeholder}
        value={props?.value}
        onChange={(event, newValue, newPlainTextValue, mentions) => {
          props?.setValue(newValue)
          
          newValue = newValue.replace(
            /\@\[([^\]]+)\]\((\d+)\)/g,
            `<a href="${process.env.REACT_APP_PUBLIC_URL + 'profile/post/$2'
            }" className='mention fs-5'><strong>*$1</strong></a>`
          )

          console.log("new", newValue)
          props.setMessage(filterBadWords(newValue))
          if (props?.setDisable) {
            if (newValue == '') props?.setDisable(true)
            else props?.setDisable(false)
          }
          setIndex(mentions?.length)
        }}
        style={mentionsInputStyle}
        id={props.id}
        onKeyDown={props?.onKeyDown}
      >
        <Mention
          style={mentionStyle}
          data={users}
          trigger={'*'}
          displayTransform={(id: any, display: any): any => `${"  *" + display}`}
          renderSuggestion={renderSuggestion}
          // appendSpaceOnAdd={true}
          onAdd={(id, display) => {
            if (id && props?.mentionUserIds) {
              let mentionUserIds = props?.mentionUserIds.filter((user_id: any) => id != user_id)
              mentionUserIds.push(id)
              props?.setMentionUserIds(mentionUserIds)
            }
          }}
        />
      </MentionsInput >
    </>
  )
}

const mapStateToProps = (user: any) => ({
  user,
})

const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  get_username_list: (data: any) => dispatch(USERACTIONS.get_username_list(data)),
})

Mentions = connect(mapStateToProps, mapDispatchToProps)(Mentions)

export { Mentions }
