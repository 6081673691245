import axios from 'axios'
import * as ACTION_TYPES from './Types'
import {
  API_USERS_LIST,
  API_USER_DETAILS,
  API_USER_FOLLOWERS,
  API_USER_FOLLOWINGS,
  API_USER_SUGGESTION_LIST,
  API_USER_FOLLOW,
  API_USER_UNFOLLOW,
  API_USER_REMOVE,
  API_FRIEND_USER_SUGGESTION_LIST,
  API_GET_COUNTRY_LIST,
  API_SET_THEME_DETAILS,
  API_USER_SIGNOUT,
  API_GET_USERNAME_LIST,
  API_FRIEND_LIST,
} from '../../constants/Api'
import {loaderChange} from '../Loader/Reducer'

// GET USER LIST
export const user_list = (data: any) => (dispatch: any) => {
  return axios
    .post(API_USERS_LIST, data)
    .then((res) => {
      dispatch({type: ACTION_TYPES.FETCH_USER_LIST_SUCCESS, payload: res.data})
      return res.data
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.FETCH_USER_LIST_ERROR,
        payload: err.response,
      })
      throw err
    })
}

// GET USER LIST
export const get_username_list = (data: any) => (dispatch: any) => {
  return axios
    .post(API_GET_USERNAME_LIST, data)
    .then((res) => {
      dispatch({type: ACTION_TYPES.FETCH_USERNAME_LIST_SUCCESS, payload: res.data})
      return res.data
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.FETCH_USERNAME_LIST_ERROR,
        payload: err.response,
      })
      throw err
    })
}

export const set_theme_details = (data: any) => (dispatch: any) => {
  return axios
    .post(API_SET_THEME_DETAILS, data)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      dispatch({
        payload: err.response,
      })
      throw err
    })
}

export const sign_out = (data: any) => (dispatch: any) => {
  dispatch(loaderChange(true))
  return axios
    .post(API_USER_SIGNOUT, data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((res) => {
      localStorage.clear()
      dispatch(loaderChange(false))
      window.location.href = process.env.REACT_APP_BANANRY_URL + 'auth/signout' || ''
    })
    .catch((err) => {
      dispatch(loaderChange(false))
      dispatch({
        payload: err.response,
      })
      throw err
    })
}

export const get_countries = (data: any) => (dispatch: any) => {
  return axios
    .post(API_GET_COUNTRY_LIST, data)
    .then((res) => {
      dispatch({type: ACTION_TYPES.FETCH_COUNTRY_LIST_SUCCESS, payload: res.data})
      return res.data
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.FETCH_COUNTRY_LIST_ERROR,
        payload: err.response,
      })
      throw err
    })
}

export const user_suggestion =
  (data: any) => (dispatch: (arg0: {type: string; payload: any}) => void) => {
    return axios
      .post(API_USER_SUGGESTION_LIST, data)
      .then((res) => {
        dispatch({type: ACTION_TYPES.FETCH_USER_SUGG_LIST_SUCCESS, payload: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({
          type: ACTION_TYPES.FETCH_USER_SUGG_LIST_ERROR,
          payload: err.response,
        })
        throw err
      })
  }

export const friend_user_suggestion =
  (data: any) => (dispatch: (arg0: {type: string; payload: any}) => void) => {
    return axios
      .post(API_FRIEND_USER_SUGGESTION_LIST, data)
      .then((res) => {
        dispatch({type: ACTION_TYPES.FETCH_FRIEND_USER_SUGG_LIST_SUCCESS, payload: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({
          type: ACTION_TYPES.FETCH_FRIEND_USER_SUGG_LIST_ERROR,
          payload: err.response,
        })
        throw err
      })
  }

export const update_user_suggestion =
  (data: any) => (dispatch: (arg0: {type: string; payload: any}) => void) => {
    return dispatch({
      type: ACTION_TYPES.UPDATE_FRIEND_USER_SUGG_LIST,
      payload: data,
    })
  }

export const user_details =
  (data: any) => (dispatch: (arg0: {type: string; payload: any}) => void) => {
    return axios
      .post(API_USER_DETAILS, data)
      .then((res) => {
        dispatch({type: ACTION_TYPES.FETCH_USER_DETAILS_SUCCESS, payload: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({
          type: ACTION_TYPES.FETCH_USER_DETAILS_ERROR,
          payload: err.response,
        })
        throw err
      })
  }

export const user_followers =
  (data: any) => (dispatch: (arg0: {type: string; payload: any}) => void) => {
    return axios
      .post(API_USER_FOLLOWERS, data)
      .then((res) => {
        dispatch({type: ACTION_TYPES.FETCH_USER_FOLLOWERS_SUCCESS, payload: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({
          type: ACTION_TYPES.FETCH_USER_FOLLOWERS_ERROR,
          payload: err.response,
        })
        throw err
      })
  }

export const user_followings =
  (data: any) => (dispatch: (arg0: {type: string; payload: any}) => void) => {
    return axios
      .post(API_USER_FOLLOWINGS, data)
      .then((res) => {
        dispatch({type: ACTION_TYPES.FETCH_USER_FOLLOWINGS_SUCCESS, payload: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({
          type: ACTION_TYPES.FETCH_USER_FOLLOWINGS_ERROR,
          payload: err.response,
        })
        throw err
      })
  }

export const follow_user =
  (data: any) => (dispatch: (arg0: {type: string; payload: any}) => void) => {
    return axios
      .post(API_USER_FOLLOW, data)
      .then((res) => {
        dispatch({type: ACTION_TYPES.FETCH_USER_FOLLOW_SUCCESS, payload: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({
          type: ACTION_TYPES.FETCH_USER_FOLLOW_ERROR,
          payload: err.response,
        })
        throw err
      })
  }

export const unfollow_user =
  (data: any) => (dispatch: (arg0: {type: string; payload: any}) => void) => {
    return axios
      .post(API_USER_UNFOLLOW, data)
      .then((res) => {
        dispatch({type: ACTION_TYPES.FETCH_USER_FOLLOW_SUCCESS, payload: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({
          type: ACTION_TYPES.FETCH_USER_FOLLOW_ERROR,
          payload: err.response,
        })
        throw err
      })
  }

export const remove_user =
  (data: any) => (dispatch: (arg0: {type: string; payload: any}) => void) => {
    return axios
      .post(API_USER_REMOVE, data)
      .then((res) => {
        dispatch({type: ACTION_TYPES.FETCH_USER_REMOVE_SUCCESS, payload: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({
          type: ACTION_TYPES.FETCH_USER_REMOVE_ERROR,
          payload: err.response,
        })
        throw err
      })
  }

export const get_friends_list = (data: any) => (dispatch: any) => {
  return axios
    .post(API_FRIEND_LIST, data)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw err
    })
}
