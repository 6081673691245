import * as ACTION_TYPES from './Types'
import {initialChatStateType} from '../../types'

const initialState = {
  message: '',
  error: null,
  chatList: {total: 0, filteredTotal: 0},
  selectedChatList: {},
  chat: {},
  page: 1,
  length: 10,
}

const chatReducer = (
  state: initialChatStateType = initialState,
  action: {type: any; payload: {data: any; message: any}}
) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CHAT_LIST_SUCCESS:
      return {
        ...state,
        chatList: {total: action.payload.data.data.length, filteredTotal: action.payload.data.data},
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_SEL_CHAT_LIST_SUCCESS:
      
      return {
        ...state,
        chatList: {total: action.payload.data.length, filteredTotal: action.payload.data},
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_CHAT_LIST_ERROR:
    case ACTION_TYPES.FETCH_SEL_CHAT_LIST_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload.data,
      }
      break
    default:
      return state
  }
}

export default chatReducer
