import { useContext, useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import { ChatContext } from '../../../../app/modules/apps/utils/ChatProvider'
import { useIntl } from 'react-intl'

/* eslint-disable jsx-a11y/anchor-is-valid */
let Dropdown1 = (props: any) => {
  const [userId, setUserId] = useState(Number(localStorage.getItem("user_id")));
  const intl = useIntl()
  const { selectedChatRoom, chatlist, blockUser, unBlockUser, deleteChat } = useContext(ChatContext)
  const [show, setShow] = useState(false)
  const [showBlock, setShowBlock] = useState(false)
  const [showUnBlock, setShowUnBlock] = useState(false)

  const handleCloseUnBlock = () => setShowUnBlock(false)
  const handleShowUnBlock = () => setShowUnBlock(true)

  const handleCloseBlock = () => setShowBlock(false)
  const handleShowBlock = () => setShowBlock(true)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const blockUserAction = () => {
    blockUser(selectedChatRoom?.id, selectedChatRoom?.User[0]?.id)
    props.setIsMenuOpen(false)
    handleCloseBlock()
    chatlist(userId, '')
  }

  const unBlockUserAction = () => {
    unBlockUser(selectedChatRoom?.id, selectedChatRoom?.User[0]?.id)
    props.setIsMenuOpen(false)
    handleCloseUnBlock()
    chatlist(userId, '')
  }

  const deleteChatAction = (delete_type: string) => {
    deleteChat(selectedChatRoom?.id, delete_type)
    props.setIsMenuOpen(false)
    handleClose()
  }

  return (
    <>
      <div
        className={`${props.isMenuOpen ? 'd-block position-absolute r-0' : 'd-none'
          } menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px`}
      >
        <div className='menu-item p-3 '>
          <a className='menu-link px-3' onClick={() => handleShow()}>
            {intl.formatMessage({ id: 'TEXT.DELETE_CHAT' })}
          </a>
          <div className='separator mb-3 opacity-75'></div>
          {selectedChatRoom?.is_block_chat &&
            selectedChatRoom?.blocked_by == localStorage.getItem('user_id') && (
              <a className='menu-link px-3' onClick={handleShowUnBlock}>
                {intl.formatMessage({ id: 'TEXT.UNBLOCK' })}
              </a>
            )}
          {!selectedChatRoom?.is_block_chat && (
            <a className='menu-link px-3' onClick={handleShowBlock}>
              {intl.formatMessage({ id: 'TEXT.BLOCK' })}
            </a>
          )}
        </div>
      </div>

      {/* delete modal */}
      <Modal show={show} onHide={handleClose}>
        <ModalBody>{intl.formatMessage({ id: 'TEXT.CHAT_DELETE' })}</ModalBody>
        <ModalFooter>
          <button
            className='btn btn-secondary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => handleClose()}
          >
            {intl.formatMessage({ id: 'BTN.CANCEL' })}
          </button>

          <button
            className='btn btn-light-primary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => deleteChatAction('for_me')}
          >
            {intl.formatMessage({ id: 'BTN.FOR_ME' })}
          </button>
          {!props?.isForMe && (
            <button
              className='btn btn-light-primary theme-same-button'
              type='button'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_search_users'
              onClick={() => deleteChatAction('for_everyone')}
            >
              {intl.formatMessage({ id: 'BTN.FOR_EVERYONE' })}
            </button>
          )}
        </ModalFooter>
      </Modal>

      {/*Un block modal */}
      <Modal show={showBlock} onHide={handleCloseBlock}>
        <ModalBody>{intl.formatMessage({ id: 'TEXT.CHAT_BLOCK' })}</ModalBody>
        <ModalFooter>
          <button
            className='btn btn-secondary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => {
              handleCloseBlock()
            }}
          >
            {intl.formatMessage({ id: 'BTN.CANCEL' })}
          </button>
          <button
            className='btn btn-primary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => {
              blockUserAction()
            }}
          >
            {intl.formatMessage({ id: 'BTN.CONFIRM' })}
          </button>
        </ModalFooter>
      </Modal>

      {/*block modal */}
      <Modal show={showUnBlock} onHide={handleCloseUnBlock}>
        <ModalBody>{intl.formatMessage({ id: 'TEXT.CHAT_UNBLOCK' })}</ModalBody>
        <ModalFooter>
          <button
            className='btn btn-secondary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => {
              handleCloseUnBlock()
            }}
          >
            {intl.formatMessage({ id: 'BTN.CANCEL' })}
          </button>
          <button
            className='btn btn-primary theme-same-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_search_users'
            onClick={() => {
              unBlockUserAction()
            }}
          >
            {intl.formatMessage({ id: 'BTN.CONFIRM' })}
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export { Dropdown1 }
