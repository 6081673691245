import * as ACTION_TYPES from './Types'
import {initialSearchType} from '../../types'

const initialState = {
  message: '',
  error: null,
  searchList: [],
}

const searchReducer = (
  state: initialSearchType = initialState,
  action: {type: any; payload: {data: any; message: any}}
) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        searchList: action?.payload?.data,
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_SEARCH_LIST_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }

    default:
      return state
  }
}

export default searchReducer
