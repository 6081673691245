import { FC, useEffect, useState, useRef } from 'react'
import clsx from 'clsx'
import { defaultUserInfos, KTSVG, UserInfoModel } from '../../helpers'
import { ChatState } from '../../../app/modules/apps/utils/ChatProvider'
import Moment from 'react-moment'
import { Dropdown4 } from '../content/dropdown/Dropdown4'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { filterBadWords } from '../../../app/utils'
import { Mentions } from '../widgets/social-feed/Mentions'
import { EmojiWidget } from '../widgets/social-feed/EmojiWidget'
import { EmojiClickData } from 'emoji-picker-react'
import { getProfileName } from '../../../config/ChatLogics'
const parse = require('html-react-parser')

type Props = {
  isDrawer?: boolean
  user_id?: number
  messageList?: any
  setMessageList?: any
  chatId?: any
  fromGroupPage?: any
  isGroupChat?: boolean
}

const ChatInner: FC<Props> = ({
  isDrawer = false,
  messageList = [],
  fromGroupPage,
  isGroupChat = false,
}) => {
  const intl = useIntl()
  const divRef = useRef<HTMLDivElement>(null)
  const user_id = localStorage.getItem('user_id')
  const [show, setShow] = useState(false)
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [value, setValue] = useState('')
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const [room, setRoom] = useState('abc')
  const ref: any = useRef()
  const [file, setFile] = useState('')
  const [preview, setPreview] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isForMe, setIsForMe] = useState(false)
  const [selectedMessageId, setSelectedMessageId] = useState()
  const chatLoader = useSelector((state: any) => state.loader.chatLoader)
  const { sendMessage, selectedChatRoom } = ChatState()
  const [mentionUserIds, setMentionUserIds]: any = useState([])
  // console.log('selectedChatRoom in chatinner', selectedChatRoom)
  // const { sendMessage } = useChatBox()

  // useEffect(() => {
  //   socket.on('receive_message', (data: any) => {
  //     // console.log("receive_message",data[0])
  //     messageList.push(data[0])
  //     // console.log("fdgdfgdfg",messageList)
  //     setMessageList = messageList
  //   })
  // }, [socket])

  // useEffect(() => {
  // socket.emit("read_by",{"user_id":user_id,"msg_id":153})
  // socket.on("read_by_success",(data:any)=>{
  //   console.log("SFs",data)
  // })
  // }, [messageList])

  // useEffect(() => {
  //   socket.emit('join_room', { room: room })
  //   // socket.emit('get_messages', {"chat_id":chatId,"page":1,"limit":10})
  //   // socket.on("receive_get_messages",(data:any)=>{
  //   //   console.log(data,"datatast")
  //   // })
  // }, [socket])

  const handleSendMessage = () => {
    // const newMessage = { chat_id: chatId, user_id: user_id, content: message, room: 'room' };
    if (!message.trim()) return
    sendMessage(message, selectedChatRoom?.room_name, mentionUserIds)
    setMentionUserIds([])
    setMessage('')
    setValue('')
    // const newMessage = {
    //   user_id: Number(user_id),
    //   content: message,
    //   other_user_id: Number(user_id) == 200 ? 200 : 204,
    //   file: [],
    // }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  const handleImageUpload = () => {
    ref.current.click()
  }

  const handleFile = (e: any) => {
    setPreview(URL.createObjectURL(e.target.files[0]))
    setFile(e.target.files[0])
  }

  useEffect(() => {
    divRef?.current && divRef.current.scrollTo({ top: 1000000 })
  }, [messageList])


  const onClick = (emojiData: EmojiClickData, event: MouseEvent) => {
    setMessage((prevValue: any) => prevValue + emojiData.emoji)
    setValue((prevValue: any) => prevValue + emojiData.emoji)
  }

  return (
    <>
      <div
        className='card-body'
        id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
      >
        <div
          ref={divRef}
          className={clsx('scroll-y  me-n5 pe-5', { 'h-300px h-lg-270px': !isDrawer })}
          data-kt-element='messageList'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='520px'
          data-kt-scroll-dependencies={
            isDrawer
              ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
              : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
          }
          data-kt-scroll-wrappers={
            isDrawer
              ? '#kt_drawer_chat_messenger_body'
              : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
          }
          data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
        >
          {messageList &&
            messageList.length > 0 &&
            messageList.map((message: any, index: any) => {
              const userInfo = userInfos[message?.sender]
              const state = message?.sender != Number(user_id) ? 'info' : 'primary'
              const templateAttr = {}
              if (message?.template) {
                Object.defineProperty(templateAttr, 'data-kt-element', {
                  value: `template-${message.type}`,
                })
              }
              const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message?.sender != Number(user_id) ? 'start' : 'end'
                }`
              return (
                <div
                  key={`message${index}`}
                  className={clsx(contentClass, 'mb-2', { 'd-none': message?.template })}
                  {...templateAttr}
                >
                  <div
                    className={clsx(
                      'd-flex flex-column align-items',
                      `align-items-${message?.sender != Number(user_id) ? 'start' : 'end'}`
                    )}
                  >
                    {fromGroupPage && (
                      <div className='d-flex align-items-center mb-2'>
                        {message?.User?.profile_pic ? (
                          <div className='symbol symbol-35px symbol-circle'>
                            <img
                              alt={
                                message?.User?.name
                                  ? `${getProfileName(message?.User?.name)}`
                                  : message?.User?.email?.charAt(0)?.toUpperCase()
                              }
                              src={message?.User?.profile_pic}
                            />
                          </div>
                        ) : (
                          <div className='symbol symbol-35px symbol-circle'>
                            <span className='symbol-label bg-light-warning text-warning 40px'>
                              {message?.User?.name
                                ? `${getProfileName(message?.User?.name)}`
                                : message?.User?.email.charAt(0).toUpperCase()}
                            </span>
                          </div>
                        )}
                        {/* <div className="symbol  symbol-35px symbol-circle "><img alt="Pic" src={message?.User?.profile_pic} /></div> */}
                        <div className='ms-3'>
                          <a
                            href='#'
                            className='fs-5 fw-bold text-gray-900 text-hover-primary me-1'
                          >
                            {message?.User?.name}
                          </a>
                        </div>
                      </div>
                    )}

                    <div
                      className={clsx(
                        'p-2 rounded overlay overlay-wrapper',
                        `bg-light-${state}`,
                        'text-dark fw-semibold mw-lg-400px min-w-75px'
                        // `text-${message?.sender != Number(user_id) ? 'start' : 'end'}`
                      )}
                    >
                      <span className='pe-7'>
                        {message?.content ? parse(message?.content) : ''}{' '}
                      </span>

                      <div className='text-gray-600 fs-9 justify-content-end mt-1 text-end'>
                        <span className='w-50px'>
                          <Moment
                            locale={localStorage.getItem('language') || 'en'}
                            format='hh:mm A'
                          >
                            {message?.created_at}
                          </Moment>
                        </span>
                      </div>
                      <div className='overlay-layer align-items-start justify-content-end pe-2 pt-4 bg-opacity-2 ps-5' onClick={() => {
                        setSelectedMessageId(message?.id)
                        setIsMenuOpen(true)
                        if (message?.sender != Number(user_id)) {
                          setIsForMe(true)
                        } else {
                          setIsForMe(false)
                        }
                      }}>
                        <KTSVG
                          path='/media/icons/duotune/bancial/trashCan.svg'
                          className={clsx("svg-icon-6 ms-2 text-gray-800")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      <div
        className={clsx(selectedChatRoom?.is_block_chat ? 'mt-17' : '', 'card-footer pt-5 pb-6')}
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        {selectedChatRoom?.is_block_chat ? (
          <p className='text-center'>
            <strong>{intl.formatMessage({ id: 'TEXT.CHAT_BLOCK_MSG' })}</strong>
          </p>
        ) : (
          <>
            {isGroupChat ? (
              <Mentions
                class='form-control form-control-flush mb-3'
                data-kt-autosize='true'
                placeholder={intl.formatMessage({ id: 'PLACEHOLDER.TYPE_MSG' })}
                message={message}
                setMessage={setMessage}
                onKeyDown={onEnterPress}
                setMentionUserIds={setMentionUserIds}
                mentionUserIds={mentionUserIds}
                show={show}
                value={value}
                setValue={setValue}
              />
            ) : (
              <textarea
                className='form-control form-control-flush mb-3'
                rows={1}
                data-kt-element='input'
                placeholder={intl.formatMessage({ id: 'PLACEHOLDER.TYPE_MSG' })}
                value={message}
                onChange={(e) => setMessage(filterBadWords(e.target.value))}
                onKeyDown={onEnterPress}
              ></textarea>
            )}

            <div className='d-flex flex-stack mt-2'>
              <div className='d-flex align-items-center me-2'>
                {/* <button
                  className='btn btn-sm btn-icon btn-active-light-primary me-1'
                  type='button'
                  data-bs-toggle='tooltip'
                  title='Coming soon'
                >
                  <i className='fa-solid fa-paperclip fs-3'></i>
                </button> */}
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary me-1'
                  type='button'
                  onClick={() => setShow(!show)}
                >
                  <KTSVG
                    path='/media/icons/duotune/bancial/faceSmile.svg'
                    className={clsx("svg-icon-3 fs-3")}
                  />
                </button>
              </div>
              <button
                className='btn btn-primary theme-same-button'
                type='button'
                disabled={chatLoader || !message.trim()}
                data-kt-element='send'
                onClick={(e) => {
                  e.preventDefault()
                  handleSendMessage()
                }}
              >
                {intl.formatMessage({ id: 'BTN.SEND' })}
                {chatLoader ? <i className='px-2 fas fa-circle-notch fa-spin'></i> : ''}
              </button>
            </div>

            {preview && (
              <div className='d-flex flex-stack mt-5'>
                <img src={preview} alt='uploaded Image' />
              </div>
            )}
            <div
              className={`${show ? 'd-flex flex-stack mt-5 overflow-auto' : 'd-flex flex-stack'}`}
            >
              <div
                className={`${show ? '' : 'hidden'}`}
                style={{ display: show ? 'block' : 'none' }}
                id={`kt_emoji_panel-${Math.random().toString()}`}
              >
                <div className='d-flex pb-5'>
                  {/* width='100%' */}
                  <EmojiWidget onEmojiClick={onClick} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Dropdown4
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        isForMe={isForMe}
        setSelectedMessageId={setSelectedMessageId}
        selectedMessageId={selectedMessageId}
      />
    </>
  )
}

export { ChatInner }
