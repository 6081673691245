import React from 'react';
import { useSelector } from 'react-redux';
import './Loader.css';

const Loading = ({ children }: any) => {
  // const isLoading = useSelector(s => s?.api?.loading);
  // console.log("loding",isLoading)

  const isLoading = useSelector((state: any) => state.loader.isLoading)
  // console.log("🚀 ~ file: Loader.tsx:10 ~ isLoading:", isLoading)

  return (
    <>
      {/* {(isLoading) && <div className="spinner-wrap" >
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>} */}
      {isLoading && <div id='preloader'></div>}
      {children}
    </>
  )
}

export default Loading;