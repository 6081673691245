import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'

const Error404: FC = () => {
  const intl = useIntl()
  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>{intl.formatMessage({ id: 'INFO.OOPS' })}</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>{intl.formatMessage({ id: 'INFO.PAGE_NOT_FOUND' })}</div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className='mb-3'>
        <img
          src={toAbsoluteUrl('/media/auth/404-error.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className='mb-0'>
        <a href={process.env.REACT_APP_BANANRY_URL || "/"} className='btn btn-sm btn-primary'>
          {intl.formatMessage({ id: 'BTN.RETURN_HOME' })}
        </a>
      </div>
      {/* end::Link */}
    </>
  )
}

export { Error404 }
