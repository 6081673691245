import io from 'socket.io-client'
import Moment from 'react-moment'
import moment from 'moment'

//To concate the path for the public folder
export const concatPublicUrl = (pathname) => process.env.PUBLIC_URL + pathname

export const getSocketConn = (user_id) =>
  io(process.env.REACT_APP_CHAT_URL, {
    query: {
      token: localStorage.getItem('token'),
    },
  })

export const formatRelativeTime = (date) => {
  const now = moment() // Get the current time using Moment.js
  const duration = moment.duration(now.diff(date)) // Calculate the duration between the date and current time

  // Check if the duration is within 0 seconds (just now)
  if (duration.asSeconds() < 1) {
    return 'Just now'
  }

  // Check if the duration is within a minute
  if (duration.asMinutes() < 1) {
    return `${Math.floor(duration.asSeconds())}s`
  }

  // Check if the duration is within an hour
  if (duration.asHours() < 1) {
    return `${Math.floor(duration.asMinutes())}m`
  }

  // Check if the duration is within a day
  if (duration.asDays() < 1) {
    return `${Math.floor(duration.asHours())}h`
  }

  // Check if the duration is within a month
  if (duration.asMonths() < 1) {
    return `${Math.floor(duration.asDays())}d`
  }

  // Check if the duration is within a year
  if (duration.asYears() < 1) {
    return `${Math.floor(duration.asMonths())}mo`
  }

  // Otherwise, return the formatted date using React-moment
  // <Moment locale={localStorage.getItem('language') || 'en'} format='MMM D'>
  return (
    <Moment locale={'en'} format='MMM D'>
      {date}
    </Moment>
  )
}

export const setMomentLocale = (locale) => {
  if (locale != 'en') {
    import(`moment/locale/${locale}`)
      .then(() => {
        Moment.locale(locale)
      })
      .catch((error) => {
        console.error(`Failed to load locale: ${locale}`, error)
      })
  }
}

export const filterBadWords = (content) => {
  let crime = [
    'pick pocketing',
    'shoplifting',
    'traffic offences = breaking the rules of the road and driving',
    'drunk driving',
    'jay walking',
    'running a red light',
    'speeding',
    'vandalism',
    'crime',
    'murder',
    'theft',
    'trafficking',
    'hijacking',
    'terrorism',
    'smuggling',
    'shoplifting',
    'vandalism',
    'teenage criminal/ juvenile delinquent',
    'abduction',
    'arson',
    'assault',
    'burglary',
    'child abuse',
    'drug trafficking',
    'false imprisonment',
    'fraud',
    'hacking',
    'hijacking',
    'human trafficking exploitation',
    'murder (homicide USA)',
    'premeditated murder',
    'unpremeditated murder',
    'manslaughter',
    'attempted murder',
    'patricide',
    'genocide',
    'euthanasia',
    'organized crime',
    'organised crime',
    'smuggling',
    'terrorism',
    'white collar crime',
    'terrorist',
    'gun',
    'gun shooting',
    'bombing',
    'gun crime',
    'hate crime',
    'criminality',
    'corruption',
    'assassin',
    'assassination',
    'hand gun',
    'shooting',
    'cops',
    'kill',
    'killing',
    'domestic crime',
    'dirty bomb',
    'deaths',
    'death threat',
    'hostage',
    'explosion',
    'explosives',
    'explosive',
    'gang',
    'gangs',
    'national security',
    'state of emergency',
    'nuclear',
    'incident',
    'looting',
    'lockdown',
    'standoff',
    'riot',
    'shots fired',
    'crash',
    'national security',
    'state of emergency',
    'threat',
    'breach',
    'chemical spill',
    'chemical',
    'infection',
    'infection',
    'racism',
    'cartel',
    'drug cartel',
    'narcotics',
    'drugs',
    'war',
    'kidnap',
    'kidnapping',
    'drug trade',
    'smuggle',
    'smuggler',
    'smuggling',
    'terror',
    'terrorist',
    'attack',
    'target',
    'weapon',
    'ammunition',
    'bomb',
    'weapon',
    'bomber',
    'bombing',
    'suicide',
    'suicidal',
    'pirates',
    'plot',
    'islamist',
    'swat',
    'hack',
    'hacker',
    'hacking',
    'malware',
    'virus',
    'trojan',
    'spam',
    'spammer',
    'spamming',
    'phishing',
    'phreaking',
    'brute force',
    'drunk',
    'cyber',
    'cyber attack',
    'scam',
    'scammer',
    'scamming',
    'fraud',
    'fraudster',
    'bomb',
  ]
  let bad = [
    '2g1c',
    '2 girls 1 cup',
    'acrotomophilia',
    'alabama hot pocket',
    'alaskan pipeline',
    'anal',
    'anilingus',
    'anus',
    'apeshit',
    'arsehole',
    'ass',
    'asshole',
    'assmunch',
    'auto erotic',
    'autoerotic',
    'babeland',
    'baby batter',
    'baby juice',
    'ball gag',
    'ball gravy',
    'ball kicking',
    'ball licking',
    'ball sack',
    'ball sucking',
    'bangbros',
    'bangbus',
    'bareback',
    'barely legal',
    'barenaked',
    'bastard',
    'bastardo',
    'bastinado',
    'bbw',
    'bdsm',
    'beaner',
    'beaners',
    'beaver cleaver',
    'beaver lips',
    'beastiality',
    'bestiality',
    'big black',
    'big breasts',
    'big knockers',
    'big tits',
    'bimbos',
    'birdlock',
    'bitch',
    'bitches',
    'black cock',
    'blonde action',
    'blonde on blonde action',
    'blowjob',
    'blow job',
    'blow your load',
    'blue waffle',
    'blumpkin',
    'bollocks',
    'bondage',
    'boner',
    'boob',
    'boobs',
    'booty call',
    'brown showers',
    'brunette action',
    'bukkake',
    'bulldyke',
    'bullet vibe',
    'bullshit',
    'bung hole',
    'bunghole',
    'busty',
    'butt',
    'buttcheeks',
    'butthole',
    'camel toe',
    'camgirl',
    'camslut',
    'camwhore',
    'carpet muncher',
    'carpetmuncher',
    'chocolate rosebuds',
    'cialis',
    'circlejerk',
    'cleveland steamer',
    'clit',
    'clitoris',
    'clover clamps',
    'clusterfuck',
    'cock',
    'cocks',
    'coprolagnia',
    'coprophilia',
    'cornhole',
    'coon',
    'coons',
    'creampie',
    'cum',
    'cumming',
    'cumshot',
    'cumshots',
    'cunnilingus',
    'cunt',
    'darkie',
    'date rape',
    'daterape',
    'deep throat',
    'deepthroat',
    'dendrophilia',
    'dick',
    'dildo',
    'dingleberry',
    'dingleberries',
    'dirty pillows',
    'dirty sanchez',
    'doggie style',
    'doggiestyle',
    'doggy style',
    'doggystyle',
    'dog style',
    'dolcett',
    'domination',
    'dominatrix',
    'dommes',
    'donkey punch',
    'double dong',
    'double penetration',
    'dp action',
    'dry hump',
    'dvda',
    'eat my ass',
    'ecchi',
    'ejaculation',
    'erotic',
    'erotism',
    'escort',
    'eunuch',
    'fag',
    'faggot',
    'fecal',
    'felch',
    'fellatio',
    'feltch',
    'female squirting',
    'femdom',
    'figging',
    'fingerbang',
    'fingering',
    'fisting',
    'foot fetish',
    'footjob',
    'frotting',
    'fuck',
    'fuck buttons',
    'fuckin',
    'fucking',
    'fucktards',
    'fudge packer',
    'fudgepacker',
    'futanari',
    'gangbang',
    'gang bang',
    'gay sex',
    'genitals',
    'giant cock',
    'girl on',
    'girl on top',
    'girls gone wild',
    'goatcx',
    'goatse',
    'god damn',
    'gokkun',
    'golden shower',
    'goodpoop',
    'goo girl',
    'goregasm',
    'grope',
    'group sex',
    'g-spot',
    'guro',
    'hand job',
    'handjob',
    'hard core',
    'hardcore',
    'hentai',
    'homoerotic',
    'honkey',
    'hooker',
    'horny',
    'hot carl',
    'hot chick',
    'how to kill',
    'how to murder',
    'huge fat',
    'humping',
    'incest',
    'intercourse',
    'jack off',
    'jail bait',
    'jailbait',
    'jelly donut',
    'jerk off',
    'jigaboo',
    'jiggaboo',
    'jiggerboo',
    'jizz',
    'juggs',
    'kike',
    'kinbaku',
    'kinkster',
    'kinky',
    'knobbing',
    'leather restraint',
    'leather straight jacket',
    'lemon party',
    'livesex',
    'lolita',
    'lovemaking',
    'make me come',
    'male squirting',
    'masturbate',
    'masturbating',
    'masturbation',
    'menage a trois',
    'milf',
    'missionary position',
    'mong',
    'motherfucker',
    'mound of venus',
    'mr hands',
    'muff diver',
    'muffdiving',
    'nambla',
    'nawashi',
    'negro',
    'neonazi',
    'nigga',
    'nigger',
    'nig nog',
    'nimphomania',
    'nipple',
    'nipples',
    'nsfw',
    'nsfw images',
    'nude',
    'nudity',
    'nutten',
    'nympho',
    'nymphomania',
    'octopussy',
    'omorashi',
    'one cup two girls',
    'one guy one jar',
    'orgasm',
    'orgy',
    'paedophile',
    'paki',
    'panties',
    'panty',
    'pedobear',
    'pedophile',
    'pegging',
    'penis',
    'phone sex',
    'piece of shit',
    'pikey',
    'pissing',
    'piss pig',
    'pisspig',
    'playboy',
    'pleasure chest',
    'pole smoker',
    'ponyplay',
    'poof',
    'poon',
    'poontang',
    'punany',
    'poop chute',
    'poopchute',
    'porn',
    'porno',
    'pornography',
    'prince albert piercing',
    'pthc',
    'pubes',
    'pussy',
    'queaf',
    'queef',
    'quim',
    'raghead',
    'raging boner',
    'rape',
    'raping',
    'rapist',
    'rectum',
    'reverse cowgirl',
    'rimjob',
    'rimming',
    'rosy palm',
    'rosy palm and her 5 sisters',
    'rusty trombone',
    'sadism',
    'santorum',
    'scat',
    'schlong',
    'scissoring',
    'semen',
    'sex',
    'sexcam',
    'sexo',
    'sexy',
    'sexual',
    'sexually',
    'sexuality',
    'shaved beaver',
    'shaved pussy',
    'shemale',
    'shibari',
    'shit',
    'shitblimp',
    'shitty',
    'shota',
    'shrimping',
    'skeet',
    'slanteye',
    'slut',
    's&m',
    'smut',
    'snatch',
    'snowballing',
    'sodomize',
    'sodomy',
    'spastic',
    'spic',
    'splooge',
    'splooge moose',
    'spooge',
    'spread legs',
    'spunk',
    'strap on',
    'strapon',
    'strappado',
    'strip club',
    'style doggy',
    'suck',
    'sucks',
    'suicide girls',
    'sultry women',
    'swastika',
    'swinger',
    'tainted love',
    'taste my',
    'tea bagging',
    'threesome',
    'throating',
    'thumbzilla',
    'tied up',
    'tight white',
    'tit',
    'tits',
    'titties',
    'titty',
    'tongue in a',
    'topless',
    'tosser',
    'towelhead',
    'tranny',
    'tribadism',
    'tub girl',
    'tubgirl',
    'tushy',
    'twat',
    'twink',
    'twinkie',
    'two girls one cup',
    'undressing',
    'upskirt',
    'urethra play',
    'urophilia',
    'vagina',
    'venus mound',
    'viagra',
    'vibrator',
    'violet wand',
    'vorarephilia',
    'voyeur',
    'voyeurweb',
    'voyuer',
    'vulva',
    'wank',
    'wetback',
    'wet dream',
    'white power',
    'whore',
    'worldsex',
    'wrapping men',
    'wrinkled starfish',
    'xx',
    'xxx',
    'yaoi',
    'yellow showers',
    'yiffy',
    'zoophilia',
  ]
  let prohibitedWords = crime.concat(bad)
  prohibitedWords.forEach((word) => {
    if (content.search(' ' + word + ' ') > 0) {
      content = content.replace(' ' + word + ' ', ' *** ')
    } else if (content == word) {
      content = content.replace(word, '***')
    } else if (content.search(' ' + word) > 0) {
      content = content.replace(' ' + word, ' ***')
    } else if (content.search(word + ' ') > 0) {
      content = content.replace(word + ' ', '*** ')
    }
  })

  return content
}
