//FETCH  Actions
export const FETCH_USER_LIST_SUCCESS = 'FETCH_USER_LIST_SUCCESS'
export const FETCH_USER_LIST_ERROR = 'FETCH_USER_LIST_ERROR'

export const FETCH_USERNAME_LIST_SUCCESS = 'FETCH_USERNAME_LIST_SUCCESS'
export const FETCH_USERNAME_LIST_ERROR = 'FETCH_USERNAME_LIST_ERROR'

export const FETCH_COUNTRY_LIST_SUCCESS = 'FETCH_COUNTRY_LIST_SUCCESS'
export const FETCH_COUNTRY_LIST_ERROR = 'FETCH_COUNTRY_LIST_ERROR'

export const FETCH_USER_SUGG_LIST_SUCCESS = 'FETCH_USER_SUGG_LIST_SUCCESS'
export const FETCH_USER_SUGG_LIST_ERROR = 'FETCH_USER_SUGG_LIST_ERROR'

export const FETCH_FRIEND_USER_SUGG_LIST_SUCCESS = 'FETCH_FRIEND_USER_SUGG_LIST_SUCCESS'
export const FETCH_FRIEND_USER_SUGG_LIST_ERROR = 'FETCH_FRIEND_USER_SUGG_LIST_ERROR'
export const UPDATE_FRIEND_USER_SUGG_LIST = 'UPDATE_FRIEND_USER_SUGG_LIST'

export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS'
export const FETCH_USER_DETAILS_ERROR = 'FETCH_USER_DETAILS_ERROR'

export const FETCH_USER_FOLLOWERS_SUCCESS = 'FETCH_USER_FOLLOWERS_SUCCESS'
export const FETCH_USER_FOLLOWERS_ERROR = 'FETCH_USER_FOLLOWERS_ERROR'

export const FETCH_USER_FOLLOWINGS_SUCCESS = 'FETCH_USER_FOLLOWINGS_SUCCESS'
export const FETCH_USER_FOLLOWINGS_ERROR = 'FETCH_USER_FOLLOWINGS_ERROR'

export const FETCH_USER_FOLLOW_SUCCESS = 'FETCH_USER_FOLLOW_SUCCESS'
export const FETCH_USER_FOLLOW_ERROR = 'FETCH_USER_FOLLOW_ERROR'

export const FETCH_USER_UNFOLLOW_SUCCESS = 'FETCH_USER_UNFOLLOW_SUCCESS'
export const FETCH_USER_UNFOLLOW_ERROR = 'FETCH_USER_UNFOLLOW_ERROR'

export const FETCH_USER_REMOVE_SUCCESS = 'FETCH_USER_REMOVE_SUCCESS'
export const FETCH_USER_REMOVE_ERROR = 'FETCH_USER_REMOVE_ERROR'
