/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { lazy, FC, Suspense, useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter, Navigate, useNavigate } from 'react-router-dom'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { App } from '../App'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { AuthPage } from '../pages/auth/Auth'
import ReduxToastr, { toastr } from 'react-redux-toastr'
import { getSocketConn, setMomentLocale } from '../utils'
import { useDispatch, useSelector } from 'react-redux'
import SocketProvider from '../modules/apps/utils/SocketProvider'
import axios from 'axios'
import { ThemeModeType, useThemeMode } from '../../_metronic/partials'
import { setLanguageWithoutReaload } from '../../_metronic/i18n/Metronici18n'
import { useLayout } from '../../_metronic/layout/core'
import { JitsiCallMobile } from '../../_metronic/partials/modals/jitsi-call/JitsiCallMobile'

const SocialPage = lazy(() => import('../modules/social/SocialPage'))
const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  // let navigate = useNavigate();
  const userId = localStorage.getItem('user_id')
  const newChatSocket = getSocketConn(userId)
  const dispatch = useDispatch()
  // const { limit } = useSelector((state: any) => state.notification)
  const [limit, setLimit] = useState(30)
  const { updateMode, updateMenuMode } = useThemeMode()
  const { setLayoutTypeWithoutReaload } = useLayout()

  // change mode
  const changeMode = (_mode: ThemeModeType) => {
    setLayoutTypeWithoutReaload(_mode === 'light' ? 'light-sidebar' : 'dark-sidebar')
    updateMenuMode(_mode)
    updateMode(_mode)
  }

  useEffect(() => {
    if (window.location.pathname != "/jitsi-call-mobile" && localStorage.getItem('user_id') != "" && localStorage.getItem('token') != "" && localStorage.getItem('token') != null && !localStorage.getItem("user_name")) {
      let token = localStorage.getItem('token');
      axios
        .get(process.env.REACT_APP_API_URL + `user/get_user_details`, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then((res: any) => {
          if (res.data.status == "200" && res.data.status == 200) {
            let theme_mode = res.data.data.is_theme == '0' ? 'light' : 'dark'
            localStorage.setItem('theme_mode', theme_mode)
            changeMode(theme_mode === 'light' ? 'light' : 'dark')

            localStorage.setItem('language', res.data.data.current_languages)
            setLanguageWithoutReaload(res.data.data.current_languages || 'en')
            // res.data.data.current_languages &&
            //   setMomentLocale(res.data.data.current_languages || 'en')
          }
        })
    }

  }, [])

  useEffect(() => {
    if (userId && newChatSocket) {
      newChatSocket.on('connect', () => {
        if (newChatSocket.connected) {
          // console.log('socket is connected successfully')

          //code for connecting users to all rooms
          newChatSocket.emit('connect_user', {
            user_id: userId,
          })

          newChatSocket.emit('chat_list', { user_id: userId, page: 1, limit: 100, search: '' })
          newChatSocket.emit('group_chat_list', { user_id: userId, page: 1, limit: 100, search: '' })
          newChatSocket.io.on('error', (error: any) => {
            console.log('Chat provider Socket connection error.', error)
          })
        }
      })
      newChatSocket.on('connect_error', (error: any) => {
        console.log("error.message", error.message)
        if (error.message == "JWT token is invalid.") {
          window.location.href = process.env.REACT_APP_PUBLIC_URL + 'error/401'
        }
      });

    }

    // newChatSocket.on('disconnect', () => {
    //   console.log("disconnect_user", userId)
    //   // newChatSocket.emit('disconnect_user', { user_id: userId })
    // })

    return () => {
      newChatSocket.disconnect();
      // newChatSocket.emit('disconnect_user', { user_id: userId })
    }
  }, [userId, newChatSocket])

  return (
    <>
      <BrowserRouter basename={PUBLIC_URL}>
        <ReduxToastr />
        <SocketProvider socket={newChatSocket}>
          <Routes>
            <Route element={<App />}>
              <Route path='error/*' element={<ErrorsPage />} />
              <Route
                path='jitsi-call-mobile'
                element={
                  <SuspensedView>
                    <JitsiCallMobile />
                  </SuspensedView>
                }
              />
              <>
                {/* <Route path='/*' element={<PrivateRoutes />} /> */}
                <Route index element={<Navigate to='/feed' />} />
                <Route
                  path='/feed'
                  element={
                    <MasterLayout newChatSocket={newChatSocket} setLimit={setLimit} limit={limit} />
                  }
                >
                  <Route index element={<SocialPage newChatSocket={newChatSocket} />} />
                </Route>

                <Route
                  element={
                    <MasterLayout newChatSocket={newChatSocket} setLimit={setLimit} limit={limit} />
                  }
                >
                  <Route
                    path='/profile/*'
                    element={
                      <SuspensedView>
                        <ProfilePage />
                      </SuspensedView>
                    }
                  />

                  <Route
                    path='/chat/*'
                    element={
                      <SuspensedView>
                        <ChatPage newChatSocket={newChatSocket} />
                      </SuspensedView>
                    }
                  />

                  <Route
                    path='/social/*'
                    element={
                      <SuspensedView>
                        <SocialPage />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='/post/*'
                    element={
                      <SuspensedView>
                        <SocialPage />
                      </SuspensedView>
                    }
                  />

                  <Route
                    path='/auth/:token'
                    element={
                      <SuspensedView>
                        <AuthPage />
                      </SuspensedView>
                    }
                  />
                  {/* Page Not Found */}
                  <Route path='*' element={<Navigate to='/error/404' />} />
                </Route>
              </>
            </Route>
          </Routes>
        </SocketProvider>
      </BrowserRouter>
    </>
  )
}

export const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { AppRoutes }
