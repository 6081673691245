import { useIntl } from 'react-intl'
import { FC, useEffect } from 'react'
import { ILayout, useLayout } from '../../core'
import { setLanguage, useLang } from '../../../i18n/Metronici18n'
// import {set_theme_details} from '../../../../store/User/Actions'
import { connect } from 'react-redux'
import * as USERACTIONS from '../../../../store/User/Actions'

let Footer: FC = (props: any) => {
  const intl = useIntl()
  const { config } = useLayout()
  const lang = useLang()
  const { setLayoutType, setToolbarType } = useLayout()

  const languages = [
    {
      lang: 'en',
      name: 'English',
    },
    {
      lang: 'hi',
      name: 'Hindi',
    },
    {
      lang: 'ar',
      name: 'Arabic',
    },
    {
      lang: 'fr',
      name: 'French',
    },
    {
      lang: 'de',
      name: 'German',
    },
    {
      lang: 'it',
      name: 'Italian',
    },
    {
      lang: 'ja',
      name: 'Japanese',
    },
    {
      lang: 'zh',
      name: 'Mandarin',
    },
    {
      lang: 'es',
      name: 'Spanish',
    },
    {
      lang: 'pt',
      name: 'portuguese',
    },
    {
      lang: 'ru',
      name: 'Russian',
    },
  ]
  const currentLanguage = languages.find((x) => x.lang === lang)

  useEffect(() => {
    updateDOM(config)
  }, [config])

  return (
    <>
      <div className='footer-link mb-5 d-flex'>
        <select
          name='locale'
          className='me-5 form-control language_dropdwn'
          id='locale'
          value={currentLanguage?.lang}
          onChange={(e) => {
            localStorage.setItem('language', e.target.value)
            props.set_theme_details({
              language: e.target.value,
            })
            setLanguage(e.target.value)
          }}
        >
          <option value=''>Please Select Language</option>
          {languages.map((l) => (
            <option value={l.lang} key={l.lang}>
              {l.name}
            </option>
          ))}
        </select>
        <a
          href={process.env.REACT_APP_BANANRY_URL + 'about-us'}
          className='text-gray-800'
          target="_blank"
        >
          {intl.formatMessage({ id: 'MENU.ABOUT_US' })}
        </a>
        &nbsp; <span className='slash'>/</span>
        <a
          href={process.env.REACT_APP_BANANRY_URL + 'privacy-policy'}
          target='blank'
          className='text-gray-800'
        >
          {intl.formatMessage({ id: 'MENU.PRIVACY' })}
        </a>
        &nbsp; <span className='slash'>/</span>
        <a
          href={process.env.REACT_APP_BANANRY_URL + 'copy-right'}
          target='blank'
          className='text-gray-800'
        >
          {intl.formatMessage({ id: 'MENU.COPYRIGHTS' })}
        </a>
        &nbsp; <span className='slash'>/</span>
        <a
          href={process.env.REACT_APP_BANANRY_URL + 'terms-condition'}
          target='blank'
          className='text-gray-800'
        >
          {intl.formatMessage({ id: 'MENU.TERMS' })}
        </a>
      </div>

      <div className='footer-color copyright'>
        <span>&copy; {new Date().getFullYear().toString()}</span>
        <a href={process.env.REACT_APP_BANANRY_URL} target='_blank' className='footer-color '>
          {' '}
          Bananry<sup>&#8482;</sup> Inc.
        </a>
        &nbsp; {intl.formatMessage({ id: 'TEXT.ALL_RIGHTS_RESERVE' })}
      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

interface RootState {
  user: []
}

const mapStateToProps = (state: RootState) => ({
  userStore: state.user,
})

const mapDispatchToProps = (dispatch: any) => ({
  set_theme_details: (data: any) => dispatch(USERACTIONS.set_theme_details(data)),
})

Footer = connect(mapStateToProps, mapDispatchToProps)(Footer)

export { Footer }
