import clsx from 'clsx'
import {checkIsActive} from '../../../helpers'
import {useLocation} from 'react-router'
import { Link } from 'react-router-dom'

// const itemClass = 'ms-1 ms-lg-3'
// const btnClass =
//   'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
// const userAvatarClass = 'symbol-35px symbol-md-40px'
// const btnIconClass = 'svg-icon-1'

type Props = {
  to: string
  title: string
}

const PageNavbar: React.FC<Props> = ({to, title}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)

  return (
    <>
      <li>
        <Link to={to} className={clsx('page-nav-link', {active: isActive}, {'theme-btn': isActive})}>
          {title}
        </Link>
      </li>
    </>
  )
}

export {PageNavbar}
