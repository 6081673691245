const initialState = {
  notifications: null,
  limit: 2
}

const notificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.payload
      }
    case 'CHANGE_LIMIT':
      return {
        ...state,
        limit: action.payload
      }

    default:
      return state
  }
}

export { notificationReducer }