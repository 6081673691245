import * as ACTION_TYPES from './Types'
import {initialAdsType} from '../../types'

const initialState = {
  message: '',
  error: null,
  sidebarAds: {},
}

const adsResucer = (
  state: initialAdsType = initialState,
  action: {type: any; payload: {data: any; message: any}}
) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ADVT_SUCCESS:
      return {
        ...state,
        sidebarAds: action?.payload?.data?.sidebar_ads,
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_ADVT_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      }

    default:
      return state
  }
}

export default adsResucer
