//FETCH  Actions
export const FETCH_FEED_SUCCESS = 'FETCH_FEED_SUCCESS'

export const FETCH_FEED_ERROR = 'FETCH_FEED_ERROR'

export const FETCH_SEE_MORE_FEED_SUCCESS = 'FETCH_SEE_MORE_FEED_SUCCESS'
export const FETCH_SEE_MORE_FEED_ERROR = 'FETCH_SEE_MORE_FEED_ERROR'

export const FETCH_CREATE_POST_SUCCESS = 'FETCH_CREATE_POST_SUCCESS'
export const FETCH_CREATE_POST_ERROR = 'FETCH_CREATE_POST_ERROR'

export const FETCH_LIKE_POST_SUCCESS = 'FETCH_LIKE_POST_SUCCESS'
export const FETCH_LIKE_POST_ERROR = 'FETCH_LIKE_POST_ERROR'

export const FETCH_REMOVE_LIKE_POST_SUCCESS = 'FETCH_REMOVE_LIKE_POST_SUCCESS'
export const FETCH_REMOVE_LIKE_POST_ERROR = 'FETCH_REMOVE_LIKE_POST_ERROR'

export const FETCH_MY_POST_LIST_SUCCESS = 'FETCH_MY_POST_LIST_SUCCESS'
export const FETCH_MY_POST_LIST_ERROR = 'FETCH_MY_POST_LIST_ERROR'

export const FETCH_ADD_COMMENT_SUCCESS = 'FETCH_ADD_COMMENT_SUCCESS'
export const FETCH_ADD_COMMENT_ERROR = 'FETCH_ADD_COMMENT_ERROR'

export const FETCH_REPLY_TO_COMMENT_SUCCESS = 'FETCH_REPLY_TO_COMMENT_SUCCESS'
export const FETCH_REPLY_TO_COMMENT_ERROR = 'FETCH_REPLY_TO_COMMENT_ERROR'

export const FETCH_COMMENT_LIST_SUCCESS = 'FETCH_COMMENT_LIST_SUCCESS'
export const FETCH_COMMENT_LIST_ERROR = 'FETCH_COMMENT_LIST_ERROR'

export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS'
export const ADD_COMMENT_ERROR = 'ADD_COMMENT_ERROR'

export const FETCH_UPDATED_FEED_SUCCESS = 'FETCH_UPDATED_FEED_SUCCESS'

export const FETCH_SEE_MORE_POSTS_SUCCESS = 'FETCH_SEE_MORE_POSTS_SUCCESS'
export const FETCH_SEE_MORE_POSTS_ERROR = 'FETCH_SEE_MORE_POSTS_ERROR'

export const FETCH_LIKE_POST_USERS_LIST_SUCCESS = 'FETCH_LIKE_POST_USERS_LIST_SUCCESS'
export const FETCH_LIKE_POST_USERS_LIST_ERROR = 'FETCH_LIKE_POST_USERS_LIST_ERROR'
