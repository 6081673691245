import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'

const SidebarLogo = () => {
  const {config} = useLayout()
  // const appSidebarDefaultMinimizeDesktopEnabled =
  //   config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  // const appSidebarDefaultCollapseDesktopEnabled =
  //   config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  // const toggleType = appSidebarDefaultCollapseDesktopEnabled
  //   ? 'collapse'
  //   : appSidebarDefaultMinimizeDesktopEnabled
  //   ? 'minimize'
  //   : ''
  // const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  // const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default
  return (
    <div className='app-sidebar-logo px-5' id='kt_app_sidebar_logo'>
      <Link to='/'>
        {config.layoutType === 'dark-sidebar' ? (
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/bananry-light-logo2.png')}
            className='h-30px app-sidebar-logo-default w-118px'
          />
        ) : (
          <>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/bananary-logo2.png')}
              className='h-30px app-sidebar-logo-default theme-light-show w-118px'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/bananary-logo2.png')}
              className='h-30px app-sidebar-logo-default theme-dark-show  w-118px'
            />
          </>
        )}

        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/favicon.png')}
          className='h-20px app-sidebar-logo-minimize'
        />
      </Link>
    </div>
  )
}

export {SidebarLogo}
