//FETCH  Actions
export const FETCH_PHOTO_SUCCESS = 'FETCH_PHOTO_SUCCESS'
export const FETCH_PHOTO_ERROR = 'FETCH_PHOTO_ERROR'

export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS'
export const UPLOAD_PHOTO_ERROR = 'UPLOAD_PHOTO_ERROR'

export const FETCH_FOLDER_SUCCESS = 'FETCH_FOLDER_SUCCESS'
export const FETCH_FOLDER_ERROR = 'FETCH_FOLDER_ERROR'

export const FETCH_SHARED_FOLDER_SUCCESS = 'FETCH_SHARED_FOLDER_SUCCESS'
export const FETCH_SHARED_FOLDER_ERROR = 'FETCH_SHARED_FOLDER_ERROR'

export const FETCH_SHARED_PHOTO_SUCCESS = 'FETCH_SHARED_PHOTO_SUCCESS'
export const FETCH_SHARED_PHOTO_ERROR = 'FETCH_SHARED_PHOTO_ERROR'
