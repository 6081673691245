import { useIntl } from 'react-intl'
import { ThemeModeType, useThemeMode } from './ThemeModeProvider'
import { useLayout } from '../../../../_metronic/layout/core'
import { FC } from 'react'
import { connect, useDispatch } from 'react-redux'
import * as USERACTIONS from '../../../../store/User/Actions'
import { loaderChange } from '../../../../store/Loader/Reducer'

let ThemeModeSwitcher: FC = (props: any) => {
  const intl = useIntl()
  const { setLayoutType } = useLayout()
  const { mode, updateMode, updateMenuMode } = useThemeMode()
  const dispatch = useDispatch();
  
  const switchMode = (_mode: ThemeModeType) => {
    dispatch(loaderChange(true))
    let darkMode: any = _mode === 'light' ? '0' : '1'
    props.set_theme_details({
      is_theme: darkMode
    }).then(() => {
      setLayoutType(_mode === 'light' ? 'light-sidebar' : 'dark-sidebar')
      updateMenuMode(_mode)
      updateMode(_mode)
      dispatch(loaderChange(false))
    })
  }

  return (
    <>
      <label className='form-check form-switch form-check-custom form-check-solid'>
        <span className={'menu-text'}>{intl.formatMessage({ id: 'MENU.DARK_MODE' })}</span>
        <input
          className='form-check-input w-40px h-20px mx-3'
          type='checkbox'
          value='1'
          defaultChecked={mode === 'dark' ? true : false}
          name='darkmode'
          onChange={() => (mode === 'dark' ? switchMode('light') : switchMode('dark'))}
        />
      </label>
    </>
  )
}

interface RootState {
  user: []
}

const mapStateToProps = (state: RootState) => ({
  userStore: state.user,
})

const mapDispatchToProps = (dispatch: any) => ({
  set_theme_details: (data: any) => dispatch(USERACTIONS.set_theme_details(data)),
})

ThemeModeSwitcher = connect(mapStateToProps, mapDispatchToProps)(ThemeModeSwitcher)

export { ThemeModeSwitcher }
