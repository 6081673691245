import React, { createContext } from 'react'


export const SocketContext = createContext({
  socket: {} as any
})


const SocketProvider = ({ socket, children }: any) => {

  const contextProvider: any = {
    socket
  }


  return <SocketContext.Provider value={contextProvider}>{children}</SocketContext.Provider>
}

export const SocketState = () => {
  return React.useContext(SocketContext)
}

export default SocketProvider