import {combineReducers} from 'redux'
import {reducer as toastrReducer} from 'react-redux-toastr'
import messageReducer from './Messages/Reducer'
import userReducer from './User/Reducer'
import chatReducer from './Chat/Reducer'
import postReducer from './Post/Reducer'
import photoReducer from './Photo/Reducer'
import {notificationReducer} from './Notification/Reducer'
import loaderReducer from './Loader/Reducer'
import adsResucer from './Advertisement/Reducer'
import searchReducer from './Search/Reducer'

const rootReducer = combineReducers({
  toastr: toastrReducer,
  messages: messageReducer,
  chat: chatReducer,
  user: userReducer,
  post: postReducer,
  photo: photoReducer,
  notification: notificationReducer,
  loader: loaderReducer,
  advertisement: adsResucer,
  search: searchReducer,
})

export default rootReducer
