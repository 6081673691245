import { useEffect, useState } from 'react'
import { JitsiMeeting } from '@jitsi/react-sdk'
import { useIntl } from 'react-intl'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { loaderChange } from '../../../../store/Loader/Reducer'

const JitsiCallMobile: React.FC = (props: any) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [config, setConfig] = useState({})
  const [callId, setCallId]: any = useState("")
  const [full_name, setFullName]: any = useState("")
  const [externalApi, setExternalApi]: any = useState()
  const parsed = queryString.parse(window.location.search)

  useEffect(() => {
    const abortcontroller = new AbortController()
    dispatch(loaderChange(true))
    localStorage.setItem('token', parsed.token?.toString() || "")
    setFullName(parsed?.full_name)
    setCallId(parsed?.call_id)

    if (parsed?.call_type == 'audio') {
      setConfig({
        disableSelfView: false,
        startScreenSharing: true,
        startAudioOnly: true,
        toolbarButtons: ['microphone', 'camera', 'desktop', 'filmstrip'],
        disableDeepLinking: true,
        prejoinPageEnabled: false
      })
    } else if (parsed?.call_type == 'video') {
      setConfig({
        disableSelfView: false,
        startScreenSharing: true,
        toolbarButtons: ['microphone', 'camera', 'desktop', 'filmstrip'],
        disableDeepLinking: true,
        prejoinPageEnabled: false
      })
    }
    dispatch(loaderChange(false))

    return () => {
      if (parsed?.user_type == "sender") {
        externalApi?.executeCommand('endConference')
        externalApi?.executeCommand('hangup')
      }
      abortcontroller.abort()
    }
  }, [])

  return (
    <>
      <JitsiMeeting
        domain={process.env.REACT_APP_VIDEO_DOMAIN}
        roomName={callId}
        configOverwrite={config}
        interfaceConfigOverwrite={{
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
          ENABLE_END_CONFERENCE: true,
          DEFAULT_REMOTE_DISPLAY_NAME: 'Participant',
          TOOLBAR_TIMEOUT: false
        }}
        userInfo={{
          displayName: full_name || "",
          email: '',
        }}
        spinner={() => (
          <div className='h-400px text-center pt-10'>
            <h3 className=' mt-50'>{intl.formatMessage({ id: 'TEXT.LOADING_PLS_WAIT' })}</h3>
          </div>
        )}
        onApiReady={(externalApi) => {
          // here you can attach custom event listeners to the Jitsi Meet External API
          // set profile pic
          externalApi?.executeCommand('avatarUrl', parsed?.profile)

          // you can also store it locally to execute commands
          setExternalApi(externalApi)
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.height = '100vh'
        }}
      />
    </>
  )
}

export { JitsiCallMobile }
