import * as ACTION_TYPES from './Types'
import {initialPostStateType} from '../../types'

const initialState = {
  message: '',
  error: null,
  feedList: {total_page: 0, filteredTotal: 0, record: []},
  commentList: {total_page: 0, filteredTotal: 0, record: []},
  page: 1,
  length: 9,
}

const postReducer = (
  state: initialPostStateType = initialState,
  action: {
    type: any
    payload: {
      is_liked: any
      post_id: any
      data: any
      message: any
    }
  }
) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_FEED_SUCCESS:
      return {
        ...state,
        feedList: {
          total_page: action.payload.data.total_page,
          filteredTotal: action.payload.data.length,
          record: action.payload.data.posts,
        },
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_SEE_MORE_FEED_SUCCESS:
      return {
        ...state,
        feedList: {
          total_page: action.payload.data.total_page,
          filteredTotal: action.payload.data.length,
          record: [...state.feedList.record, ...action.payload.data.posts],
        },
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_SEE_MORE_POSTS_SUCCESS:
      return {
        ...state,
        feedList: {
          total_page: action.payload.data.total_page,
          filteredTotal: action.payload.data.length,
          record: [...state.feedList.record, ...action.payload.data.posts],
        },
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.FETCH_UPDATED_FEED_SUCCESS:
      let updated_records = state.feedList.record.map((rec: any) => {
        if (rec.id == action.payload.post_id) {
          rec.is_liked = action.payload.is_liked
          rec.total_likes = action.payload.is_liked == 1 ? rec.total_likes + 1 : rec.total_likes - 1
        }
        return rec
      })

      return {
        ...state,
        feedList: {
          ...state.feedList,
          record: updated_records,
        },
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_COMMENT_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_FEED_ERROR:
    case ACTION_TYPES.FETCH_COMMENT_LIST_ERROR:
      return {
        ...state,
        error: action.payload.data,
      }
    case ACTION_TYPES.ADD_COMMENT_SUCCESS:
      return {
        ...state,

        data: action.payload,
        error: null,
      }

    case ACTION_TYPES.FETCH_MY_POST_LIST_SUCCESS:
      return {
        ...state,
        feedList: {
          total_page: action.payload.data.total_page,
          filteredTotal: action.payload.data.length,
          record: action.payload.data.posts,
        },
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.ADD_COMMENT_ERROR:
    case ACTION_TYPES.FETCH_MY_POST_LIST_ERROR:
    case ACTION_TYPES.FETCH_SEE_MORE_POSTS_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload.data,
      }
      break
    default:
      return state
  }
}

export default postReducer
