import { FC, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import * as ADSACTIONS from '../../../../store/Advertisement/Actions'
import parse from "html-react-parser"
let Advertisements: FC = (props: any) => {
  const selectedAd: any = useSelector((state: any) => state.advertisement.sidebarAds);

  useEffect(() => {
    props.get_advertisement()
  }, [])

  useEffect(() => {
    if (selectedAd?.id) {
      props.view_ad({ ads_id: selectedAd?.id })
      // console.log("selectedAd", selectedAd)
    }
  }, [selectedAd])
  return (
    <>
      {selectedAd && (
        <a href={selectedAd?.ads_url} className='card card-custom overlay border-0' target="_blank" >
          <div className='card-body p-0' onClick={() => props.click_ad({ ads_id: selectedAd?.id })} >
            <div className='overlay-wrapper align-items-center d-flex'>
              {selectedAd?.code ? parse(selectedAd?.code) : ""}
            </div>
          </div>
        </a>
      )}
    </>
  )
}

interface RootState {
  advertisement: []
}

const mapStateToProps = (state: RootState) => ({
  advertisementStore: state.advertisement,
})

const mapDispatchToProps = (dispatch: any) => ({
  get_advertisement: () => dispatch(ADSACTIONS.get_advertisement()),
  click_ad: (data: any) => dispatch(ADSACTIONS.click_ad(data)),
  view_ad: (data: any) => dispatch(ADSACTIONS.view_ad(data)),
})
Advertisements = connect(mapStateToProps, mapDispatchToProps)(Advertisements)

export default Advertisements
