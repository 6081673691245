import * as ACTION_TYPES from './Types'
import {initialPhotoStateType} from '../../types'

const initialState = {
  message: '',
  error: null,
  photoList: {total_page: 0, filteredTotal: 0, record: [], folder_details: {}},
  folderList: {total_page: 0, record: []},
  page: 1,
  length: 10,
}

const photoReducer = (
  state: initialPhotoStateType = initialState,
  action: {type: any; payload: {data: any; message: any}}
) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PHOTO_SUCCESS:
      return {
        ...state,
        photoList: {
          total_page: action.payload?.data.length,
          record: action.payload?.data,
          folder_details: action.payload?.data?.folder_details,
        },
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_SHARED_PHOTO_SUCCESS:
      return {
        ...state,
        photoList: {
          total_page: action.payload?.data.length,
          record: action.payload?.data,
          folder_details: action.payload?.data?.folder_details,
        },
        message: action.payload.message,
        error: null,
      }
    case ACTION_TYPES.FETCH_FOLDER_SUCCESS:
      return {
        ...state,
        folderList: {
          total_page: action.payload?.data?.total_page,
          records: action.payload?.data?.records,
        },
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_SHARED_FOLDER_SUCCESS:
      return {
        ...state,
        folderList: {
          total_page: action.payload?.data?.total_page,
          records: action.payload?.data?.records,
        },
        message: action.payload.message,
        error: null,
      }

    case ACTION_TYPES.FETCH_PHOTO_ERROR:
    case ACTION_TYPES.FETCH_FOLDER_ERROR:
    case ACTION_TYPES.FETCH_SHARED_PHOTO_ERROR:
      break
    default:
      return state
  }
}

export default photoReducer
