/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useEffect, useState } from 'react'
import { SearchInner } from './SearchInner'
import { doSearch } from '../../../../store/Search/Actions'
import { FETCH_SEARCH_LIST_ERROR, FETCH_SEARCH_LIST_SUCCESS } from '../../../../store/Search/Types'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

type Props = {
  search?: any
  setSearch?: any
}

const HeaderSearchMenu: FC<Props> = ({ search, setSearch }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { searchList } = useSelector((state: any) => state.search)
  const [showSearch, setShowSearch] = useState(false)

  useEffect(() => {
    // set serch value empty if search value empty
    if (search != "") {
      setShowSearch(true);
      doSearch(search).then(res => {
        dispatch({ type: FETCH_SEARCH_LIST_SUCCESS, payload: res })
      }).catch((err: any) => {
        dispatch({ type: FETCH_SEARCH_LIST_ERROR, payload: err })
      })
    } else {
      setShowSearch(false)
      dispatch({ type: FETCH_SEARCH_LIST_SUCCESS, payload: [] })
    }
  }, [search])


  return (
    <>
      {showSearch ?
        <div
          className='card menu menu-sub menu-sub-dropdown menu-column w-100 position-absolute my-3 tooltip_search show'
          data-kt-menu='true'
        >
          <div className='scroll-y mh-375px me-1'>
            <div className='p-2'>
              {searchList?.artists?.length > 0 && (<SearchInner group_name={intl.formatMessage({ id: 'TEXT.ARTIST' })}
                records={searchList?.artists} setSearch={setSearch} />)}
              {searchList?.songs?.length > 0 && (<SearchInner group_name={intl.formatMessage({ id: 'TEXT.SONG' })}
                records={searchList?.songs} setSearch={setSearch} />)}
              {searchList?.albums?.length > 0 && (<SearchInner group_name={intl.formatMessage({ id: 'TEXT.ALBUMS' })}
                records={searchList?.albums} setSearch={setSearch} />)}
              {searchList?.playlists?.length > 0 && (<SearchInner group_name={intl.formatMessage({ id: 'TEXT.PLAYLIST' })}
                records={searchList?.playlists} setSearch={setSearch} />)}
              {searchList?.promoters?.length > 0 && (<SearchInner group_name={intl.formatMessage({ id: 'TEXT.PRODUCER' })}
                records={searchList?.promoters} setSearch={setSearch} />)}
              {searchList?.events?.length > 0 && (<SearchInner group_name={intl.formatMessage({ id: 'TEXT.EVENT' })}
                records={searchList?.events} setSearch={setSearch} />)}

              {searchList?.artists?.length == 0 && searchList?.songs?.length == 0
                && searchList?.albums?.length == 0 && searchList?.promoters?.length == 0
                && searchList?.playlists?.length == 0 && searchList?.events?.length == 0 && (<h3 className='mt-2 fw-normal fs-5 pb-3'>{intl.formatMessage({ id: "TEXT.NO_RECORDS_FOUND" })}</h3>)}

            </div>
          </div>
        </div>
        : <></>}
    </>

  )
}

export { HeaderSearchMenu }


