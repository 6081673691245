/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useLayout } from '../../../_metronic/layout/core'
import { ThemeModeType, useThemeMode } from '../../../_metronic/partials'
import { setLanguageWithoutReaload } from '../../../_metronic/i18n/Metronici18n'
import { loaderChange } from '../../../store/Loader/Reducer'
import { useDispatch } from 'react-redux'
import { setMomentLocale } from '../../utils'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const AuthPage: FC = () => {
  const { token } = useParams()
  const queryParams = window?.location?.search
  const { updateMode, updateMenuMode } = useThemeMode()
  const { setLayoutTypeWithoutReaload } = useLayout()
  let dispatch = useDispatch()

  const clearStorage = () => {
    localStorage.clear();
  }

  // change mode
  const changeMode = (_mode: ThemeModeType) => {
    setLayoutTypeWithoutReaload(_mode === 'light' ? 'light-sidebar' : 'dark-sidebar')
    updateMenuMode(_mode)
    updateMode(_mode)
  }

  useEffect(() => {
    clearStorage()
    dispatch(loaderChange(true))
    localStorage.setItem('token', token || "")
    axios
      .get(process.env.REACT_APP_API_URL + `user/get_user_details`, {
        headers: { authorization: 'Bearer ' + token },
      })
      .then((res) => {
        if (res.data.status == 200) {
          if (res.data.data.is_theme) {
            let theme_mode = res.data.data.is_theme == '0' ? 'light' : 'dark'
            localStorage.setItem('theme_mode', theme_mode)
            changeMode(theme_mode === 'light' ? 'light' : 'dark')
          }
          localStorage.setItem("unread_count", "0")
          localStorage.setItem('user_id', res.data.data.id)
          localStorage.setItem('user_name', res.data.data.username)
          localStorage.setItem('full_name', res.data.data.name)
          localStorage.setItem('first_name', res.data.data.firstname)
          localStorage.setItem('profile_pic', res.data.data.profile_pic || toAbsoluteUrl('/media/avatars/blank.png'))
          localStorage.setItem('language', res.data.data.current_languages)
          setLanguageWithoutReaload(res.data.data.current_languages || 'en')
          // res.data.data.current_languages &&
          //    setMomentLocale(res.data  .data.current_languages || 'en')
          if (queryParams) {
            if (queryParams.includes('chatPage')) {
              // /chat/private-chat?chatPage=218#
              dispatch(loaderChange(false))
              window.location.href = `/chat/private-chat${queryParams}`
            } else if (queryParams.includes('groupPage')) {
              // /chat/group-chat?groupPage=218#
              dispatch(loaderChange(false))
              window.location.href = `/chat/group-chat${queryParams}`
            } else if (queryParams.includes('postPage')) {
              // ?postPage=218#
              dispatch(loaderChange(false))
              const postId = queryParams.split('=')[1]
              window.location.href = `/social/${postId}`
            } else if (queryParams.includes('friendRequest')) {
              dispatch(loaderChange(false))
              window.location.href = `/social/business-pals`
            } else if (queryParams.includes('sharePhoto')) {
              dispatch(loaderChange(false))
              window.location.href = `/social/photos/shared`
            } else if (queryParams.includes('followUser')) {
              dispatch(loaderChange(false))
              window.location.href = `/profile/followers/${res.data.data.id}`
            } else {
              dispatch(loaderChange(false))
              window.location.href = '/feed'
            }
          } else {
            dispatch(loaderChange(false))
            window.location.href = '/feed'
          }
        } else {
          dispatch(loaderChange(false))
          window.location.href = '/error/401'
        }
      })
      .catch((er) => {
        dispatch(loaderChange(false))
        window.location.href = '/error/401'
      })
  }, [])

  return <></>
}

export { AuthPage }
