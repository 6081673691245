
export const LOADING_CHANGE = "LOADING_CHANGE"
export const CHAT_LOADING_CHANGE = "CHAT_LOADING_CHANGE"

const initialState = {
  isLoading: false,
  chatLoader: false,
}

const loaderReducer = (
  state = initialState,
  action: { type: any; payload: { data: any; message: any } }
) => {
  switch (action.type) {
    case LOADING_CHANGE:
      return {
        ...state,
        isLoading: action.payload
      }
    case CHAT_LOADING_CHANGE:
      return {
        ...state,
        chatLoader: action.payload
      }
    default:
      return state
  }
}

export default loaderReducer

export const loaderChange = (data: boolean) => {
  return {
    type: LOADING_CHANGE,
    payload: data
  }
}


export const chatLoaderChange = (data: boolean) => {
  return {
    type: CHAT_LOADING_CHANGE,
    payload: data
  }
}
