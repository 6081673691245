import axios from 'axios'
import {API_SEARCH} from '../../constants/Api'

export const doSearch = (data: any) => {
  return axios
    .get(`${API_SEARCH}?keyword=${data}`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw err
    })
}
